<template>
  <div
      class="mt-14 mb-10 border-b lg:mt-20 lg:mb-20 border-grisClair"
  >
    <div class="container mx-auto">
      <div class="grid grid-cols-1 lg:grid-cols-12 pb-12 lg:pb-20">
        <div
            class="flex flex-col col-span-12 lg:col-span-7 self-end"

        >
          <h1
              v-if="headerData.fieldTitle"
              class="font-lora text-h3 leading-[45px] lg:leading-[57px] lg:text-h1"
          >
            {{ headerData.fieldTitle }}
          </h1>
          <div
              v-if="headerData.fieldIntroduction2"
              v-html="headerData.fieldIntroduction2"
              class="HubIntroduction font-lora leading-[30px] lg:max-w-[85%] text-medium pt-4"
          />
        </div>
        <div
            v-if="headerData.fieldPushText || headerData.fieldPushLink"
            class="col-span-1 lg:col-start-10 lg:col-span-3 self-end pt-10 lg:pt-0"
        >
          <div :class="getPushClasses">
            <div
                v-if="headerData.fieldPushText"
                v-html="headerData.fieldPushText"
                class="pb-4 font-lora text-h3Small wysiwyg"
            />
            <UiCustomLink
                v-if="headerData.fieldPushLink"
                :path="headerData.fieldPushLink.uri.path"
                class="inline-block w-full cursor-pointer inline-block text-lg px-2 min-[768px]:px-4 min-[1550px]:px-6 leading-none font-bold font-raleway rounded py-4 text-center text-white lg:w-full bg-orangeCurie hover:bg-orangeHover"
            >
              {{ headerData.fieldPushLink.title }}
            </UiCustomLink>
          </div>
        </div>
        <div
            class="col-span-12 ml-[-1rem] mr-[-1rem]  mt-6 lg:mt-10 lg:mr-0 lg:ml-0 mb-9 lg:mb-20"
        >
          <UiCustomImage
              :src="headerData.fieldImage.entity.uriRawField.list[0].url"
              :alt="headerData.fieldImage.alt"
              presets="field_hub_header"
              customClass="object-cover w-full hidden lg:block rounded-[4px]"
          />
          <UiCustomImage
              :src="headerData.fieldImageMobile.entity.uriRawField.list[0].url"
              :alt="headerData.fieldImageMobile.alt"
              presets="field_hub_header_mobile"
              customClass="object-cover w-full block lg:hidden"
          />
        </div>
        <p v-if="headerData.fieldText1"
            class="col-span-12 lg:col-span-12 font-lora text-h3Medium lg:text-h2Small leading-[33px] lg:leading-[40px]"
        >
          {{ headerData.fieldText1 }}
        </p>
        <div
            v-if="headerData.fieldText2"
            class="wysiwyg col-span-12 lg:col-span-8 font-raleway text-regularMobile lg:text-regular leading-[25px] pt-6"
            v-html="headerData.fieldText2"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  headerData: {
    type: Object,
    required: true,
  },
});

const getPushClasses = computed(()  =>  {
  if (props.headerData.fieldPushText) {
    return "p-4 rounded border border-grisClair lg:p-6";
  }
  else {
    return "px-6 py-6";
  }
});
</script>
<style>
.HubIntroduction {
  a {
    @apply font-raleway font-bold text-lg inline-block underline hover:text-orangeCurie;

    display: flex;
    align-items: center;
    &:after {
      mask: url("~/assets/icons/arrowRightLink.svg") no-repeat 50% 50%;
      mask-size: cover;
      width: 16px;
      height: 16px;
      content: "";
      margin-left: 8px;
      vertical-align: middle;
      position: relative;
      top:0;
      background-color: #353537;
    }

    &:hover {
      &:after {
        background-color: #F58220;
      }
    }
  }
}
</style>
