<template>
  <ul>
    <li
      v-for="item in menuData"
      :key="item.id"
      class="pb-4 mb-4 font-raleway text-regular border-b border-gray-300 cursor-pointer last:border-b-0"
    >
      <CustomLink
        :path="`${item.pathRawField.list[0].alias}#content`"
        :customClass="
          isActive(item.pathRawField.list[0].alias) ? 'font-bold' : ''
        "
        >{{ item.fieldLabelMenu }}</CustomLink
      >
    </li>
  </ul>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import CustomLink from "../ui/CustomLink.vue";

interface MenuItem {
  id: string;
  pathRawField: {
    list: Array<{
      alias: string;
    }>;
  };
  fieldLabelMenu: string;
}

export default defineComponent({
  name: "FieldMenu",
  components: {
    CustomLink,
  },
  props: {
    menuData: {
      type: Array as () => MenuItem[],
      required: true,
    },
  },
  setup() {
    const route = useRoute();

    const isActive = (path: string): boolean => {
      return route.path === path;
    };

    return { navigateTo, isActive };
  },
});
</script>
