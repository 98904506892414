<script setup lang="ts">
import { ListFacetsFacet as Facet } from "#components";

import useQuery from "~/composables/query";

const { clearFacetsQuery } = useQuery();
const { facets, facetConditions } = storeToRefs(useList());

const facetsWithFilters = computed(() => facets.value.filter((facet) => !facet.filters || facet.filters.length > 0));

const showFilter = ref(false);

watch(showFilter, () => {
  if (showFilter.value) {
    document.body.classList.add("modal-open");
  } else {
    document.body.classList.remove("modal-open");
  }
});
</script>

<template>
  <div
    @click="showFilter = !showFilter"
    class="w-full relative flex items-center justify-between py-[12px] px-4 rounded border border-grisClair lg:rounded-0 lg:py-0 lg:px-0 lg:border-0 font-raleway font-bold text-regularMobile lg:text-[22px] leading-[120%]"
  >
    {{ $t("list.filter.action") }}
    <NuxtIcon name="filter" filled class="lg:hidden text-[22px] cursor-pointer" />
  </div>
  <button v-if="facetConditions.length > 0" @click="clearFacetsQuery" class="py-2 text-black bg-red-500 rounded">
    <span
      class="flex gap-2 items-center font-raleway text-grisFont font-bold underline text-regularMobile lg:text-regular"
    >
      <NuxtIcon name="close" />
      {{ $t("list.filter.delete") }}
    </span>
  </button>
  <div
    :class="showFilter ? 'block lg:block' : 'hidden lg:block'"
    class="fixed overflow-y-scroll lg:overflow-y-visible p-4 pb-20 lg:p-0 z-20 top-0 left-0 w-[100%] bg-white h-full lg:h-auto lg:static"
  >
    <nuxt-icon
      name="close-modal"
      filled
      class="absolute text-2xl cursor-pointer right-[13px] top-[13px] lg:hidden"
      @click="showFilter = !showFilter"
    />
    <div
      ref="filterAction"
      class="mt-10 font-raleway font-bold text-mobileTitleList lg:text-[22px] leading-[120%] lg:hidden"
    >
      {{ $t("list.filter.action") }}
    </div>
    <button
      v-if="facetConditions.length > 0"
      @click="clearFacetsQuery"
      class="pt-4 lg:p-2 mb-2 text-black bg-red-500 rounded lg:hidden"
    >
      <span class="font-raleway font-bold underline text-regularMobile lg:text-regular flex gap-2 items-center">
        <NuxtIcon name="close" />
        {{ $t("list.filter.delete") }}
      </span>
    </button>
    <Facet v-for="facet in facetsWithFilters" :key="facet.label" :facet="facet" />
    <UiButton
      url="#list"
      :buttonLabel="$t('list.filter.apply')"
      size="medium"
      :withIcon="false"
      class="lg:hidden w-full text-center mt-14 bottom-[20px] fixed max-w-[93%] lg:static"
      @click="showFilter = !showFilter"
    />
  </div>
</template>
