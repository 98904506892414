<template>
  <div class="mt-14 border-gray-300 md:border-b lg:mt-20">
    <div class="container mx-auto">
      <div
        class="grid grid-cols-1"
        :class="
          newsHeader.fieldImage
            ? 'grid md:grid-cols-12 mb-10 lg:mb-0 lg:gap-6 xl:gap-20'
            : 'mb-4 lg:mb-0 md:grid-cols-1 md:w-[70%]'
        "
      >
        <div class="flex flex-col col-span-12 justify-end lg:col-span-5">
          <span v-if="newsHeader.fieldSubtitle" class="pb-2.5 font-lora text-medium text-orangeCurie">{{
            newsHeader.fieldSubtitle
          }}</span>
          <h1 class="font-lora font-normal text-h3 lg:text-h1 leading-[45px] lg:leading-[57px] mb-6 lg:mb-10">
            {{ newsHeader.fieldTitle }}
          </h1>

          <div class="flex flex-col gap-2.5 justify-between pb-6 lg:gap-0 lg:flex-row lg:items-center lg:pb-16">
            <span v-if="newsHeader.fieldDate" class="font-lora text-medium">{{
              formatDate(newsHeader.fieldDate.value)
            }}</span>
            <div class="flex">
              <p class="font-bold font-raleway text-regularMobile lg:text-regular">{{ $t("news.shareArticle") }}</p>
              <ul class="flex gap-2.5 items-center ml-3">
                <li
                  @click="
                    copyText();
                    isCopied = !isCopied;
                  "
                  class="relative cursor-pointer"
                >
                  <nuxt-icon name="Share" filled class="text-xl" />
                  <span
                    v-show="isCopied"
                    class="absolute font-bold left-1/2 translate-x-[-50%] border border-grisClair rounded py-1 px-2"
                    >{{ $t("news.copied") }}</span
                  >
                </li>
                <li v-for="(link, index) in shareSocial" :key="index">
                  <UiCustomLink target="_blank" :path="link.url">
                    <nuxt-icon :name="link.icon" class="text-xl" />
                  </UiCustomLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex col-span-12 items-end lg:col-start-7 lg:col-span-6">
          <UiCustomImage
            :src="newsHeader.fieldImage.entity.uriRawField.list[0].url"
            :alt="newsHeader.fieldImage.alt"
            presets="field_header"
            customClass="w-full rounded-tl rounded-tr object-cover"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatDate } from "~/utils/dateUtils";

const props = defineProps({
  newsHeader: {
    type: Object,
    required: true,
  },
});

const runtimeConfig = useRuntimeConfig();
const route = useRoute();

const isCopied = ref(false);

const copyText = () => {
  navigator.clipboard.writeText(runtimeConfig.public.frontendHost + route.href);
};

const shareSocial = [
  {
    icon: "Facebook",
    url: `https://www.facebook.com/sharer/sharer.php?u=${runtimeConfig.public.frontendHost}${route.href}`,
  },
  {
    icon: "Twitter",
    url: `https://twitter.com/intent/tweet?text=${runtimeConfig.public.frontendHost}${route.href}`,
  },
  {
    icon: "LinkedIn",
    url: `https://www.linkedin.com/shareArticle/?url=${runtimeConfig.public.frontendHost}${route.href}&title=${props.newsHeader.fieldTitle}`,
  },
];
</script>
