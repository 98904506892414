import useQuery from "@/composables/query";

export default function useDateRange() {
  const route = useRoute();
  const { updateQuery } = useQuery();
  const { selectedDateRange } = storeToRefs(useList());

  const startDate = ref(route.query?.startDate ? new Date(route.query.startDate as string) : null);
  const fromMonth = ref<number | "">(startDate.value ? startDate.value.getMonth() + 1 : "");
  const fromYear = ref<number | "">(startDate.value ? startDate.value.getFullYear() : "");

  const endDate = ref(route.query?.endDate ? new Date(route.query.endDate as string) : null);
  const toMonth = ref<number | "">(endDate.value ? endDate.value.getMonth() + 1 : "");
  const toYear = ref<number | "">(endDate.value ? endDate.value.getFullYear() : "");

  const from = computed(() => {
    if (fromMonth.value && fromYear.value) {
      return new Date(Number(fromYear.value), Number(fromMonth.value) - 1, 1);
    }
    return null;
  });

  const to = computed(() => {
    if (toMonth.value && toYear.value) {
      const year = Number(toYear.value);
      const month = Number(toMonth.value);
      return new Date(year, month, 0);
    }
    return null;
  });

  const applyDates = () => {
    if (from.value && to.value) {
      selectedDateRange.value = {
        start: from.value,
        end: to.value,
      };

      updateQuery({
        startDate: formatDateWithoutTimezone(selectedDateRange.value.start),
        endDate: formatDateWithoutTimezone(selectedDateRange.value.end),
      });
    }
  };

  return {
    route,
    fromMonth,
    fromYear,
    toMonth,
    toYear,
    from,
    to,
    applyDates,
  };
}
