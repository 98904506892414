<template>
  <li v-for="(item, index) in items" :key="index" class="col-span-1 w-fit lg:w-auto">
    <component :is="item.fieldLink?.uri?.path ? UiCustomLink : 'div'" :path="item.fieldLink?.uri?.path">
      <UiCustomImage
        :src="item.fieldLogo?.entity.uriRawField.list[0].url"
        :alt="item.fieldLogo?.alt"
        presets="team_partners"
        class="min-w-[90px] w-[90px] h-[90px] max-h-[90px] lg:object-scale-down"
      />
    </component>
  </li>
</template>

<script setup lang="ts">
import type { PartnerOrSite } from "~/interfaces/team";

defineProps({
  items: Array as PropType<PartnerOrSite[]>,
});

const UiCustomLink = resolveComponent("UiCustomLink");
</script>
