<template>
  <ul class="pr-20">
    <li
      v-for="(item, index) in menuItems"
      :key="index"
      class="font-raleway text-lg leading-[140%] text-grisFont border-b border-gray-300 mb-4 pb-4 cursor-pointer hover:text-orangeCurie"
    >
      <div class="flex items-center justify-between">
        <a
          :class="test == item.link ? 'font-bold' : 'font-normal'"
          :href="item.link"
           @click="toggleSection(item.link)"
          class="w-full"
          >{{ item.name }}
        </a>
        <NuxtIcon
          v-if="item.subMenu && item.subMenu.length > 0"
          :name="openedSection === item.link ? 'minus' : 'plus'"
          class="text-xl"
           @click="toggleSection(item.link)"
        />
      </div>
      <!-- Sous-menu (si présent) -->
      <ul v-if="item.subMenu" v-show="openedSection === item.link" class="pl-4">
        <li
          v-for="subItem in item.subMenu"
          :key="subItem.name"
          class="font-raleway text-md leading-[130%] text-grisFont hover:text-orangeCurie my-4"
          :class="subItem.id === activeSubMenu ? 'font-bold' : 'font-normal'"
        >
          <a :href="item.link" @click="setSubMenu(subItem)">{{ subItem.name }}</a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useTeamNavigation } from "@/composables/team/navigation";

import type { MenuItem } from "@/interfaces/team";
import { watch } from "vue";
const route = useRoute();
const props = defineProps({
  menuItems: Array as PropType<MenuItem[]>,
  activeSection: String as PropType<string>,
  activeSubMenu: String as PropType<string>,
});

const emit = defineEmits(["set-sub-menu"]);
const { setSubMenu,toggleSection, openedSection } = useTeamNavigation(emit);
const test = ref("#presentation");

setTimeout(() => { test.value = route.hash =="" ? "#presentation" :  route.hash}, 100)
watch(
  () => route.hash,
  () => {
    if (route.hash) {
      openedSection.value = route.hash;
      test.value = route.hash;
    }
  }
);
</script>
