<template>
  <div
      :class="isFlex ? 'flex flex-wrap lg:flex-nowrap gap-4 items-center': ''"
      class="w-full">
    <div class="relative">
      <button
          id="dropdownDefaultButton"
          @click="toggleDropdown"
          class="inline-flex  text-left justify-between items-center p-3 px-6 w-full text-lg font-normal bg-white border border-grisClair text-grisFont font-raleway"
          type="button"
          :class="isDropdownOpen ? 'rounded-tr rounded-tl' : 'rounded'"
      >
        {{
          selectedItem
              ? selectedItem.name
              : $t("select")
        }}
        <nuxt-icon v-show="!isDropdownOpen" name="chevronDown" filled/>
        <nuxt-icon v-show="isDropdownOpen" name="chevronUp" filled/>
      </button>
      <!-- Dropdown menu -->
      <div
          v-show="isDropdownOpen"
          id="dropdown"
          class="absolute z-20 w-full bg-white rounded-br rounded-bl border border-grisClair border-t-0"
          :class="isFlex ? 'top-[55px] lg:max-w-[75%]': ''"
         
      >
        <ul
            class="px-6 pb-5 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
        >
          <li
              v-for="(categoryItem, category) in listItems"
              class="block pt-6  cursor-pointer text-regularMobile lg:text-regular text-grisFont font-raleway "
          >
          <span class="font-bold hover:font-bold">
          {{ category }}
          </span>
          <ul>
              <li
              v-for="item in categoryItem"
              :key="item.id"
              class="block pt-6  cursor-pointer text-regularMobile lg:text-regular text-grisFont font-raleway hover:text-orangeCurie hover:font-bold"
              :class="font-normal"
               @click="() => selectItem(item)"
              >
              {{ item.name }}
              </li>
          </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from "vue";
import CustomLink from "./CustomLink.vue";

const emit = defineEmits(['selectSubject'])
interface ListItem {
  id: string;
  name: string;
}

const props = defineProps<{
  listItems: ListItem[][];
  isFlex: false
}>();

const isDropdownOpen = ref(false);
const selectedItem = ref<ListItem | null>(null);

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const closeDropdown = () => {
  isDropdownOpen.value = false;
};

const selectItem = (item: ListItem) => {
  selectedItem.value = item;
  closeDropdown();
  emit('selectSubject', item.name)
};

</script>
