import { gql } from "graphql-tag";

export const ClinicalTrialQuery = gql`
  query ClinicalTrialQuery($id: ID!, $langcode: Langcode!) {
    entityById(entityType: NODE, id: $id, langcode: $langcode) {
      ... on NodeClinicalTrial {
        id
        langcode
        translations {
          pathRawField {
            list {
              alias
              langcode
            }
          }
        }
        fieldMetatag {
          value
        }
        fieldSubtitle
        fieldTitle
        fieldIntroduction
        fieldClinicalTrialCancerType {
          name
        }
        fieldClinicalTrialLocation1 {
          name
        }
        fieldClinicalTrialLocation2 {
          name
        }
        fieldDate {
          value
        }
        fieldDate2 {
          value
        }
        fieldClinicalTrialTarget {
          name
        }
        fieldClinicalTrialPhase {
          name
        }
        fieldPromote
        fieldDescription
        fieldLink {
          title
          uri {
              path
          }
        }
        fieldPerson {
          fieldFirstName
          fieldLastName
          pathRawField {
            list {
              alias
            }
          }
          fieldImage {
            alt
            entity {
              uriRawField {
                list {
                  url
                }
              }
            }
          }
          fieldPersonTitle {
            name
          }
        }
      }
    }
  }
`;
