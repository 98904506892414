<template>
  <div class="py-7 lg:mt-20 border-gray-300 border-y">
    <ul
      :class="{
        'justify-end': prevMenuItem === null && nextMenuItem,
        'justify-start': nextMenuItem === null && prevMenuItem,
        'justify-between': prevMenuItem && nextMenuItem,
      }"
      class="flex items-center w-full"
    >
      <li v-if="prevMenuItem">
        <CustomLink
          :path="`${prevMenuItem.pathRawField.list[0].alias}#content`"
          customClass="font-bold flex items-center font-raleway text-regular hover:text-orangeCurie"
        >
          <nuxt-icon name="chevronLeft" filled class="mt-1 mr-10 text-xl" />
          {{ prevMenuItem.fieldLabelMenu }}
        </CustomLink>
      </li>
      <li v-if="nextMenuItem">
        <CustomLink
          :path="`${nextMenuItem.pathRawField.list[0].alias}#content`"
          customClass="font-bold flex items-center font-raleway text-regular hover:text-orangeCurie"
        >
          {{ nextMenuItem.fieldLabelMenu }}
          <nuxt-icon name="chevronRight" filled class="mt-1 ml-10 text-xl" />
        </CustomLink>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import CustomLink from "~/components/ui/CustomLink.vue";

interface MenuItem {
  id: string;
  pathRawField: {
    list: Array<{
      alias: string;
    }>;
  };
  fieldLabelMenu: string;
}

export default {
  name: "FieldBottomNavigation",
  components: {
    CustomLink,
  },
  props: {
    menuData: {
      type: Array as () => MenuItem[],
      required: true,
    },
  },
  computed: {
    currentIndex(): number {
      return this.menuData.findIndex(
        (item) => item.pathRawField.list[0].alias === this.$route.path
      );
    },
    prevMenuItem(): MenuItem | null {
      const index = this.currentIndex;
      return index > 0 ? this.menuData[index - 1] : null;
    },
    nextMenuItem(): MenuItem | null {
      const index = this.currentIndex;
      return index < this.menuData.length - 1 ? this.menuData[index + 1] : null;
    },
  },
};
</script>
