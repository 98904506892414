<template>
  <div class="container mx-auto mb-[30px] lg:mb-16">
    <div class="grid gap-10 lg:gap-4 mt-14 lg:grid-cols-12 lg:mt-20">
      <div class="order-1 lg:col-span-7 flex flex-col justify-end">
        <h5 v-if="fieldSurtitle" class="text-lg font-medium font-lora text-orangeCurie">
          {{ fieldSurtitle }}
        </h5>
        <h1
          v-if="fieldTitle"
          class="font-lora mt-2 font-normal text-grisFont text-h3 lg:text-h1 leading-[45px] lg:leading-[57px] break-words"
        >
          {{ fieldTitle }}
        </h1>

        <div v-if="fieldUnit?.length > 0" class="order-3 lg:col-span-7 hidden lg:block">
          <div
            v-for="unit in fieldUnit"
            class="text-lg text-grisFont font-raleway"
            :class="fieldUnit?.length > 1 ? 'last:pt-6 first:lg:pt-[54px]' : 'pt-6 lg:pt-[54px]'"
          >
            <p class="font-normal leading-[140%]">{{ unit.fieldUnitType?.name }} :</p>
            <UiCustomLink :path="unit.pathRawField.list[0].alias || ''" class="font-bold underline">
              {{ unit.fieldTitle }}
            </UiCustomLink>
          </div>
        </div>
      </div>
      <div
        class="order-2 lg:col-span-4 lg:col-start-9 lg:row-start-1 lg:self-end"
        :class="fieldTeamMember?.length <= 1 ? 'lg:row-end-3' : ''"
      >
        <div
          v-if="fieldTeamMember?.length > 0 && fieldTeamMember?.some((member) => member.fieldChief === '1')"
          class="p-4 lg:p-6 rounded border border-grisClair"
        >
          <div
            v-for="member in fieldTeamMember?.filter((member) => member.fieldChief === '1')"
            :key="member.fieldTitle"
            class="flex lg-3 last:pb-0 group"
            :class="fieldTeamMember?.length > 1 ? 'pb-6 lg:pb-8' : ''"
          >
            <UiCustomLink  v-if="member" :path="member.fieldPerson ? member.fieldPerson.pathRawField.list[0].alias : '#'" class="flex">
              <div class="h-[90px] lg:h-[140px] min-w-[94px] lg:min-w-[140px] overflow-hidden rounded">
                <UiCustomImage
                  v-if="member?.fieldImage || member?.fieldPerson?.fieldImage"
                  :src="
                    member?.fieldImage
                      ? member?.fieldImage?.entity.uriRawField.list[0].url
                      : member?.fieldPerson.fieldImage.entity.uriRawField.list[0].url
                  "
                  :alt="member?.fieldImage ? member?.fieldImage?.alt : member?.fieldPerson?.fieldImage.alt"
                  presets="team_members_header"
                  class="w-full group-hover:scale-[1.1] transition ease delay-600 max-h-[100%]"
                />
                <NuxtImg
                    v-else
                    class="w-full group-hover:scale-[1.1] transition ease delay-600 max-h-[100%]"
                    presets="team_members_header"
                    src="/image/default.png"
                    alt="Default"
                    width="140" 
                    height="140"
                />
              </div>
              <div class="flex flex-col pl-6">
                <h3
                  class="font-lora font-normal leading-[26px] text-medium lg:text-h3Small mt-0 group-hover:text-orangeCurie"
                >
                  {{ `${member?.fieldPerson?.fieldFirstName} ${member?.fieldPerson?.fieldLastName}` }}
                </h3>
                <span class="font-raleway text-regularMobile lg:text-regular font-normal leading-[25px] mb-2">
                  {{ member?.fieldFunction ? member?.fieldFunction?.name : "" }}
                </span>
                <div class="flex items-center gap-4">
                  <div v-if="memberHasSocials(member) || member.fieldPerson?.pathRawField?.list[0]?.alias" class="flex flex-wrap items-center gap-4">
                    <UiCustomLink
                        :path="`${member.fieldPerson.pathRawField.list[0].alias}#contact`"
                    >
                      <NuxtIcon name="mail" class="text-h3Small" filled />
                    </UiCustomLink>
                    <UiCustomLink
                      v-for="(socialLink, index) in member.fieldPerson?.fieldLink"
                      :key="index"
                      :path="socialLink.uri.path"
                      class="border w-[20px] h-[20px] flex items-center justify-center rounded border-grisFont hover:text-orangeCurie hover:border-orangeCurie"
                    >
                      <NuxtIcon v-if="socialLink.title" class="text-[10px]" :name="getSocialIcon(socialLink.title)" />
                    </UiCustomLink>
                  </div>
                </div>
              </div>
            </UiCustomLink>
          </div>
        </div>
      </div>
      <div v-if="fieldUnit?.length > 0" class="order-3 lg:col-span-7 block lg:hidden">
        <div
          v-for="unit in fieldUnit"
          class="text-lg text-grisFont font-raleway"
          :class="fieldUnit?.length > 1 ? 'last:pt-6 first:lg:pt-[54px]' : ''"
        >
          <p class="font-normal leading-[140%]">{{ unit.fieldUnitType?.name }} :</p>
          <UiCustomLink :path="unit.pathRawField.list[0].alias || ''" class="font-bold underline">
            {{ unit.fieldTitle }}
          </UiCustomLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTeamIcons } from "~/composables/team/icons";

import type { FieldTeamMember, FieldUnit } from "~/interfaces/team";

defineProps({
  fieldTeamMember: Array as PropType<FieldTeamMember[]>,
  fieldTitle: String,
  fieldSurtitle: String,
  fieldUnit: Array as PropType<FieldUnit[]>,
});

const { memberHasSocials, getSocialIcon } = useTeamIcons();
</script>
