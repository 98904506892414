<template>
  <div>
    <div v-if="PublicationListData?.entityById">
      <CommonFieldHeader
        v-if="PublicationListData.entityById.fieldHeader"
        :fieldHeaderData="PublicationListData.entityById.fieldHeader"
        :isListPage="true"
        class="mt-12"
        :showIntroduction="pageNumber<2"
        :customTitle="`${PublicationListData.entityById.fieldHeader.fieldTitle}${pageNumber >1 ? ` - page ${pageNumber}`:''}`"
      />

      <div class="container mx-auto">
        <div id="list" class="grid grid-cols-12 mb-10 lg:mb-20 mt-10 lg:mt-20">
          <div class="col-span-12 block lg:hidden">
            <div class="pb-6 font-lora text-h3Medium leading-[33px] lg:text-h2Small lg:pb-10 font-normal m-0">
              {{ $t("publication.searchTitle") }}
            </div>
            <ListSearchForm list="publication" />
          </div>

          <div class="col-span-12 mb-6 lg:col-span-4 lg:mb-0 lg:pb-0 lg:pr-20">
            <ListFacets />
          </div>

          <div class="col-span-12 lg:col-start-5 lg:col-span-8 lg:pl-20 lg:border-l lg:border-grisClair">
            <h2 class="hidden lg:block pb-6 font-lora text-h3Medium lg:text-h2Small lg:pb-10 font-normal m-0">
              {{ $t("publication.searchTitle") }}
            </h2>
            <ListSearchForm list="publication" class="hidden lg:flex" />
            <ListItems v-if="searchData.count > 0" list="publication" />
            <div v-else>
              <p class="font-raleway text-regularMobile lg:text-regular">{{ $t("publication.noResults") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import useConditionsBuilder from "~/composables/conditions/builder";
import useQuery from "~/composables/query";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
import getPublicationList from "~/service/graphql/client/publication-list";

import type { Condition, Facet, OpenSearchNode } from "~/interfaces/list";
import type { SeoTags } from "~/interfaces/seo";

import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();

const config = useRuntimeConfig();
const route = useRoute();
const { t } = useI18n();

const pageNumber = computed(()=> route.query.page ?? 1);
const node = computed(() => routeStore.route?.name?.toString()!);
const baseConditions = ref<Condition[]>([
  { field: "status", values: "1", conjunction: "or" },
  { field: "type", values: "publication", conjunction: "or" },
]);
const facets = ref<Facet[]>([
  { label: t("publication.fieldPublicationDate"), type: "Date", field: "field_date" },
  { label: t("publication.computedPublicationUnit"), type: "Checkbox", field: "computed_publication_unit" },
  { label: t("publication.computedPublicationTeam"), type: "Checkbox", field: "computed_publication_team" },
  { label: t("publication.computedPublicationPlatform"), type: "Checkbox", field: "computed_publication_platform" },
  { label: t("publication.fieldPublicationNewspaper"), type: "Checkbox", field: "field_publication_newspaper" },
]);

const { setBaseConditions, setFacetConditions, setFacets, fetchSearchData, setSortOrder} = useList();
const { buildApiConditions, buildApiConditionsFromQuery } = useConditionsBuilder();
const { handleQueryBaseParameters, reset } = useQuery();
reset();
const { langcode, searchData } = storeToRefs(useList());
setFacets(facets.value);
setBaseConditions(buildApiConditions(baseConditions.value));

const { data: PublicationListData } = (await getPublicationList(node.value, langcode.value)) as {
  data: Ref<OpenSearchNode>;
};
contextStore.setContent(PublicationListData.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const backendMetatags = getBackendMetatags(PublicationListData.value?.entityById?.fieldMetatag);

const { currentPage } = storeToRefs(useList());

const getCanonicalUrl = (page) => {
  const queries = {...route.query};
  if(page>1){
  queries.page = page;
  }else{
    delete queries.page;
  }
  const queryParamsStr = Object.keys(queries).map(key => `${key}=${queries[key]}`).join("&");
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.path}${queryParamsStr ? '?'+queryParamsStr :""}`;
  return canonicalUrl;
}

const getFormattedMetatags = <SeoTags>(() => {
  const backendMetatags = getBackendMetatags(PublicationListData.value?.entityById?.fieldMetatag);
  const title = formatSeoTag(
    "title",
    (backendMetatags?.title ?? PublicationListData.value?.entityById?.fieldHeader?.fieldTitle)
  );
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? PublicationListData.value?.entityById?.fieldHeader?.fieldIntroduction2
  );
 
  const canonicalUrl = getCanonicalUrl(route.query.page?? 1);

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});
const formattedMetatags = getFormattedMetatags();

const getPaginateMeta = (page) => {
  let suffix = '';
  if(page>1){
    suffix = ` - page ${page}`;
  }
  return {title: `${formattedMetatags.title}${suffix}`, canonical_url: getCanonicalUrl(page) , description: `${formattedMetatags.description}${suffix}`};
}

watch(
  ()=> currentPage.value,
  (value)=> { 
    setMetaTags(getPaginateMeta(value))
  },
  { immediate: true, deep: true }
)
setMetaTags(getPaginateMeta(route.query.page ?? 1))

watch(
  () => route.query,
  async (query) => {
    handleQueryBaseParameters(query);

    const facetConditions = buildApiConditionsFromQuery(query);
    setFacetConditions(facetConditions);
    setSortOrder({field: "field_date", order: "DESC"});
    await fetchSearchData("publicationList");
  },
  { immediate: true, deep: true }
);

useCDNHeaders((helper) => {
  helper
    .private();
})
</script>
