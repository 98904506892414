<script setup lang="ts">
import useQuery from "~/composables/query";

import type { Facet, QueryValue } from "~/interfaces/list";

const route = useRoute();
const { updateQuery } = useQuery();

const props = defineProps({
  facet: {
    type: Object as PropType<Facet>,
    required: true,
  },
});

const queryKey = computed(() => toCamelCase(props.facet.field));
const filters = computed(() => props.facet.filters);

const selectedFilter = computed({
  get() {
    return route.query[queryKey.value] as QueryValue;
  },
  set(value: QueryValue) {
    updateQuery({ [queryKey.value]: value });
  },
});
</script>

<template>
  <ul
    v-if="filters"
    class="flex flex-col gap-2.5 RadioFilter max-h-[180px] overflow-y-auto pr-4"
    :class="filters.length > 5 ? 'overflow-y-scroll' : ''"
  >
    <li class="flex gap-2.5 items-center" v-for="(filter, index) in filters" :key="index">
      <input
        v-model="selectedFilter"
        type="radio"
        :value="filter.value"
        :id="`${filter.value}`"
        class="w-fit font-raleway text-regularMobile lg:text-regular shrink-0"
      />
      <label
        class="cursor-pointer font-raleway text-regularMobile lg:text-regular flex-auto flex justify-between"
        :class="{ 'font-bold text-orangeCurie': filter.value === selectedFilter }"
        :for="`${filter.value}`"
      >
        {{ filter.label.value }} <span v-if="facet.hideCount !== true">({{ filter.count }})</span>
      </label>
    </li>
  </ul>
</template>

<style>
.RadioFilter {
  input[type="radio"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    color: currentColor;
    width: 18px;
    height: 18px;
    border: 1px solid #353537;
    border-radius: 100%;
    display: grid;
    place-content: center;
    cursor: pointer;
  }

  input[type="radio"]::before {
    content: "";
    width: 10px;
    height: 10px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #f58220;
    border-radius: 100%;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
}
</style>
~/stores/list
