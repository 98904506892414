<template>
  <div v-if="fieldAnchorData?.length > 0" class="z-[1] border-grisClair border-y lg:border-t-0 sticky top-0 bg-white field-anchor">
    <div class="p-4 lg:py-6 container mx-auto">
      <div
        class="flex items-center justify-between cursor-pointer"
        @click="toggleList"
      >
        <div
          class="flex items-center mr-4 lg:mr-24 text-xl font-normal lg:mr-0 font-lora"
        >
          <nuxt-icon name="anchor" filled class="mr-4 text-xl" />
          {{  $t("showSummary") }}
        </div>
        <nuxt-icon name="plus" filled class="text-xl" v-show="!isListVisible" />
        <nuxt-icon name="minus" filled class="text-xl" v-show="isListVisible" />
      </div>
      <ul v-show="isListVisible" class="mt-6 md:ml-9">
        <li
          v-for="(anchor, index) in fieldAnchorData"
          :key="index"
          class="flex items-center mb-2 text-[17px] font-bold cursor-pointer hover:text-orangeCurie font-raleway"
          @click="scrollToElement(anchor.uri.path);toggleList()"
        >
          <nuxt-icon name="arrowRight" filled class="mr-6 text-xl" />
          {{ anchor.title }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "FieldAnchor",
  props: {
    fieldAnchorData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isListVisible: false,
    };
  },
  methods: {
    toggleList() {
      this.isListVisible = !this.isListVisible;
    },
    scrollToElement(anchorId) {
      const element = document.querySelector(anchorId);
      const headerOffset = 250;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      if (element) {
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
<style>
/* Use this only to remove border when top anchor border and bottom field header border are together */
.field-header.border-b + .field-anchor.border-y {
  @apply border-t-0
}
</style>
