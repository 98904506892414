<template>
<div>
  <TeamTitle :title="$t('team.teamActualities')" />

  <div
      v-for="actuality in fieldNewsTeam"
      :key="actuality.id"
      class="flex flex-col-reverse gap-4 py-4  lg:flex-row xl:gap-24 mb-6 relative"
  >
    <div class="lg:basis-[62%] flex flex-col">
                <span
                    class="w-fit rounded text-orangeCurie font-lora text-regular py-0.5">{{ actuality.fieldSubtitle }}</span>
      <h3
          class="font-lora text-medium lg:text-h3Small mb-4 lg:mb-14 mt-2.5 leading-[24px] hover:text-orangeCurie"
      >
        <UiCustomLink
            class="underline-animation-border"
            :path="actuality.pathRawField?.list[0].alias"
        >
          {{ actuality.fieldTitle }}
        </UiCustomLink>
      </h3>
      <span class="text-small font-raleway mt-auto">{{ formatDate(actuality.fieldDate.value) }}</span>
    </div>
    <div class="flex justify-end">
      <UiCustomImage
          :src="actuality.fieldImage?.entity?.uriRawField?.list[0].url"
          :alt="actuality.fieldImage?.alt"
          presets="team_actuality"
          class="object-cover w-full lg:w-full lg:max-h-[225px] lg:h-[150px] rounded-md bg-grisClair"
      />
    </div>
  </div>
  <UiCustomLink  v-if="backLink" :path="`${backLink}`" class="flex items-center text-lg font-bold underline font-raleway mt-[22px] lg:mt-[38px] hover:text-orangeCurie">
    {{  $t("actuality.discoverAlt") }}
    <NuxtIcon name="arrowRight" filled class="ml-2 text-xl" />
  </UiCustomLink>
</div>
</template>
<script setup lang="ts">
import type { FieldNewsTeam } from "~/interfaces/team";
import { useBackLink } from "~/composables/backlink";
import { formatDate } from "~/utils/dateUtils";
const { getBackLink } = useBackLink();
const backLink = getBackLink("news_list");

const props = defineProps({
  fieldNewsTeam: Array as PropType<FieldNewsTeam[]>,
});

const formatDateWithSlashes = (date: string) => {
  return date.replace(/-/g, "/");
};
</script>
