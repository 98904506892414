<script setup lang="ts">
import useDateRange from "~/composables/daterange";

import { ListFacetsDateRange as DateRange } from "#components";

import type { Facet } from "~/interfaces/list";

defineProps({
  facet: {
    type: Object as PropType<Facet>,
    required: true,
  },
});

const { fromMonth, fromYear, toMonth, toYear, from, to, applyDates } = useDateRange();
</script>

<template>
  <div>
    <div class="space-y-2 mb-4">
      <DateRange
        :label="$t('list.dateRange.from')"
        :month="fromMonth"
        :year="fromYear"
        @update:month="fromMonth = $event"
        @update:year="fromYear = $event"
      />
      <DateRange
        :label="$t('list.dateRange.to')"
        :month="toMonth"
        :year="toYear"
        @update:month="toMonth = $event"
        @update:year="toYear = $event"
      />
    </div>

    <div v-if="from && to" class="flex justify-center lg:justify-end">
      <button class="underline hover:text-orangeCurie font-bold font-raleway text-regularMobile lg:text-regular" @click="applyDates">
        {{ $t("list.datePicker.applyDates") }}
      </button>
    </div>
  </div>
</template>
