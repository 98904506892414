<template>
  <div>
    <TeamTitle :title="$t('team.seminars')" />

    <div
          v-for="(seminar, index) in fieldSeminarsTeam"
          :key="index"
          :class="index === 0 ? 'lg:border-t lg:pt-4' : ''"
          class="flex flex-col relative gap-2.5 w-full pb-4"
        >
      <span class="w-fit rounded text-orangeCurie font-lora text-[15px] lg:text-regular py-0.5">
        {{ seminar.fieldType }}
      </span>
      <UiCustomLink
        :path="seminar.pathRawField.list[0].alias"
        customClass="m-0 font font-lora leading-[25px] text-medium lg:text-h3Small hover:text-orangeCurie underline-animation-border -mt-1"
        tag="h3"
      >
        {{ seminar.fieldTitle }}
      </UiCustomLink>
      <p v-if="getSpeakers(seminar)" class="font-raleway text-regularMobile lg:text-regular leading-[25px] -mt-1">
        {{ $t("list.item.seminar.speakers") }} : {{ getSpeakers(seminar) }}
      </p>
      <div v-if="dateRange(seminar)" class="font-raleway text-small leading-[18px] mt-4 lg:mt-[30px]">
        {{ dateRange(seminar) }}
      </div>
      <div v-if="getLocation(seminar)" class="flex align-center font-raleway text-small leading-[18px]">
        <nuxt-icon name="map-alt" filled class="mr-1 text-regular flex items-center" />
        {{ getLocation(seminar) }}
      </div>
    </div>
    <UiCustomLink v-if="backLink" :path="`${backLink}`" class="flex items-center text-lg font-bold underline font-raleway mt-[22px] lg:mt-[38px] hover:text-orangeCurie">
      {{  $t("seminar.discoverAlt") }}
      <NuxtIcon name="arrowRight" filled class="ml-2 text-xl" />
    </UiCustomLink>
  </div>
</template>
<script setup lang="ts">
import type { fieldSeminars, FieldNewsSeminar } from "~/interfaces/team";
import { useBackLink } from "~/composables/backlink";
import { formatDateWithTime, formatTime, isSameDay } from "@/utils/dateUtils";
const { getBackLink } = useBackLink();
const backLink = getBackLink("seminar_list");

const props = defineProps({
  fieldSeminarsTeam: {
    type: Array as PropType<fieldSeminars[]>,
    required: true,
  }
});

const getSpeakers = (seminar:  FieldNewsSeminar) => (seminar.fieldSpeaker?.map(({ fieldFirstName = "", fieldLastName = "" }) => [fieldFirstName, fieldLastName].filter(Boolean).join(" "))
    .join(", "));



const dateRange = (seminar:  FieldNewsSeminar) => {
  const { fieldDateBegin, fieldDateEnd } = seminar;
  if (!fieldDateBegin?.value || !fieldDateEnd?.value) return false;

  return formatDateRange(fieldDateBegin.value, fieldDateEnd.value);
};

const formatDateRange = (beginString: string, endString: string) => {
  const beginDate = new Date(beginString);
  const endDate = new Date(endString);

  if (isSameDay(beginDate, endDate)) {
    return formatSingleDayEvent(beginString, endString);
  } else {
    return formatMultiDayEvent(beginString, endString);
  }
};

const formatSingleDayEvent = (beginString: string, endString: string) => {
  return `${formatDateWithTime(beginString)} - ${formatTime(endString)}`;
};

const formatMultiDayEvent = (beginString: string, endString: string) => {
  return `Du ${formatDateWithTime(beginString)} au ${formatDateWithTime(endString)}`;
};


const formatDateWithSlashes = (date: string) => {
  return date.replace(/-/g, "/");
};
const getLocation = (seminar:  FieldNewsSeminar) => {
  const { fieldSite, fieldRoom } = seminar;

  if (fieldSite && fieldRoom) {
    return `${fieldSite} - ${fieldRoom}`;
  } else {
    return fieldSite || fieldRoom || "";
  }
};

</script>
