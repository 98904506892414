<template>
  <div>
    <TeamTitle :title="$t('team.members')" :info="fieldTeamMember?.length" />
    <div class="grid grid-cols-12 gap-4 mt-10 lg:gap-6">
      <div
        v-for="(member, index) in fieldTeamMember"
        :key="index"
        class="flex flex-col col-span-6 justify-between p-4 rounded border border-grisClair lg:col-span-4"
      >
        <UiCustomLink :path="member.fieldPerson ? member.fieldPerson.pathRawField.list[0].alias : '#'">
          <UiCustomImage
            v-if="
              member?.fieldImage?.entity?.uriRawField?.list?.[0]?.url ||
              member?.fieldPerson?.fieldImage?.entity?.uriRawField?.list?.[0]?.url
            "
            :src="
              member?.fieldImage?.entity?.uriRawField?.list?.[0]?.url
                ? member?.fieldImage?.entity?.uriRawField?.list[0].url
                : member?.fieldPerson?.fieldImage?.entity?.uriRawField?.list[0]?.url
            "
            :alt="member?.fieldImage?.alt ? member?.fieldImage?.alt : member?.fieldPerson?.fieldImage?.alt"
            presets="team_members"
            class="object-cover w-full h-auto rounded"
          />
          <NuxtImg
              v-else
              class="object-cover w-full h-auto rounded"
              presets="team_members"
              src="/image/default.png"
              alt="Default"
          />
          <h3 class="font-lora font-normal leading-[120%] text-xl">
            {{
              member?.fieldTitle
                ? member?.fieldTitle
                : `${member?.fieldPerson?.fieldFirstName} ${member?.fieldPerson?.fieldLastName}`
            }}
          </h3>
          <span class="font-raleway text-small font-normal leading-[140%]">
            {{ member?.fieldFunction ? member?.fieldFunction?.name : member?.fieldPerson.fieldPersonTitle.name }}
          </span>
        </UiCustomLink>
        <div class="flex items-center gap-4 mt-4">
          <div v-if="memberHasSocials(member) || member.fieldPerson?.pathRawField?.list[0]?.alias" class="flex items-center gap-4 flex-wrap">
            <UiCustomLink
                v-if="member.fieldPerson?.pathRawField?.list[0]?.alias"
                :path="`${member.fieldPerson.pathRawField.list[0].alias}#contact`"
            >
              <NuxtIcon name="mail" class="text-h3Small" filled />
            </UiCustomLink>
            <UiCustomLink v-for="(icon, index) in member?.fieldPerson?.fieldLink" :key="index" :path="icon.uri.path">
              <NuxtIcon v-if="icon.title" :name="getSocialIcon(icon.title)" />
            </UiCustomLink>
          </div>
        </div>
      </div>
    </div>
    <div v-if="fieldTeamMemberOld?.length" class="mt-14">
      <h4 class="font-raleway text-[22px] font-bold text-grisFont leading-[120%]">
        {{ $t("team.oldMembersTeam") }}
      </h4>
      <ul class="pl-4 mt-4 list-disc">
        <li
          v-for="(oldMember, index) in fieldTeamMemberOld"
          :key="index"
          class="mb-2 text-lg font-normal font-raleway text-grisFont"
        >
          {{ oldMember.fieldTitle }}, {{ oldMember.fieldFunction.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useTeamIcons } from "~/composables/team/icons";

import type { FieldTeamMember, FieldTeamMemberOld } from "~/interfaces/team";

const props = defineProps({
  fieldTeamMember: Array as PropType<FieldTeamMember[]>,
  fieldTeamMemberOld: Array as PropType<FieldTeamMemberOld[]>,
});

const { memberHasSocials, getSocialIcon } = useTeamIcons();
</script>
