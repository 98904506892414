import { PublicationQuery } from "../../query/content/Publication";

export default async function getPublication(id: string, langcode: string) {
  const TAGS : string[] = [];
  const key = `page--${id}`;
  const { data: news } = await useAsyncData(key, async () => {
      const { value, addToCache } = await useDataCache(key)
      if (value) {
        return value
      }
      const { data: response } = await useAsyncQuery(PublicationQuery, { id, langcode });
      TAGS.push(`page:${id}`);
      if(response._value.entityById?.computedPublicationTeam){
        for (const element of response._value.entityById?.computedPublicationTeam) {
          TAGS.push(`page:${element.id}`);
        }
      }
      if(response._value.entityById?.fieldAuthorCurie){
        for (const element of response._value.entityById?.fieldAuthorCurie.filter((element) => element != null)) {
          TAGS.push(`page:${element.id}`);
        }
      }
      await addToCache(response._value, TAGS);
      return response._value;
  })
  return {data: news._value };
}
