export function objectGroupBy(items: Iterable<Object>, keySelector: (item: unknown, index: number) => PropertyKey) {
    if (!Array.isArray(items)) {
        throw new Error('Value is not an array.');
      }
    
      const result = {};
      const count = items.length;
      let val = null;
    
      for (let i = 0; i < count; i++) {
        val = keySelector(items[i]);
    
        if (val?.constructor?.name !== 'String' && val?.constructor?.name !== 'Number') {
          throw new Error('Key must be string or number.');
        }
        if (result[val] === undefined) {
          result[val] = [];
        }
    
        result[val].push(items[i]);
      }
      return result;
}