<template>
  <div class="relative">

    <div class="container mx-auto mb-14 lg:mb-20">
      <!-- Back Link -->
      <UiCustomLink
          v-if="backLink"
          :path="backLink"
          customClass="font-bold flex items-center font-raleway text-regular mt-14 lg:mt-20 mb-[22px] lg:mb-10 hover:text-orangeCurie"
      >
        <nuxt-icon name="chevronLeft" filled class="mt-1 mr-4 text-xl"/>
        {{ $t("job.jobList") }}
      </UiCustomLink>
      <!-- Back Link end -->
      <div id="job" class="grid relative grid-cols-12 pt-4 mb-14 lg:mb-20">
        <JobOfferCard :JobOffer="JobOffer" :formHidden="formHidden" @changeFormStatus="changeFormStatus"/>
        <div v-if="formHidden" class="col-span-12 lg:col-start-5 lg:col-span-8 lg:pl-20 lg:border-l lg:border-grisClair pt-10 lg:pt-0">
          <JobOfferContent :JobOffer="JobOffer"/>
        </div>
        <div v-if="!formHidden" class="col-span-12 lg:col-start-5 lg:col-span-6 lg:pl-20 lg:border-l lg:border-grisClair pt-10 lg:pt-0">
          <JobOfferForm :JobOffer="JobOffer" @changeFormStatus="changeFormStatus"/>
        </div>
      </div>
      <!-- Footer start -->
      <div v-if="JobOffer.entityById.fieldFooter" v-show="formHidden">
        <component
            v-for="paragraph in JobOffer.entityById.fieldFooter"
            :key="paragraph.fieldParagraph.id"
            :is="getComponentByType(paragraph.fieldParagraph.__typename)"
            :paragraphData="paragraph.fieldParagraph"
            class="mt-20 lg:mt-14"
        />
      </div>
      <!-- Footer end -->
    </div>
  </div>
</template>
<script setup lang="ts">
import useSeo from "~/composables/seo";
import getJobOffer from "~/service/graphql/client/job-offer/index";
import {getComponentByType} from "~/utils/componentMapper";
import {useBackLink} from "~/composables/backlink";
import type {SeoTags} from "~/interfaces/seo";
import {useContextStore} from "~/stores/context";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
const contextStore = useContextStore();
const route = useRoute();
const config = useRuntimeConfig();
const langcode = config.public.langcode;
const {data: JobOffer} = await getJobOffer(routeStore.route?.name?.toString()!, langcode);
contextStore.setContent(JobOffer.value?.entityById);
const {setMetaTags, getBackendMetatags, formatSeoTag} = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(JobOffer.value?.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? JobOffer.value?.entityById?.fieldTitle);
  const description = formatSeoTag(
      "description",
      backendMetatags?.description ?? JobOffer.value?.entityById?.fieldIntroduction,
      backendMetatags?.title ?? JobOffer.value?.entityById?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return {title, description, canonical_url: canonicalUrl, ...backendMetatags};
});

setMetaTags(formattedMetatags.value);

const {getBackLink} = useBackLink();
const backLink = getBackLink();

const { fieldTitle, fieldDescription, fieldReference, fieldDateEnd2, created, fieldJobContract, fieldJobLocation } =
  JobOffer.value?.entityById;

useJsonld(() => ({
  "@context": "https://schema.org",
  "@type": "JobPosting",
  title: fieldTitle,
  description: fieldDescription,
  identifier: {
    "@type": "PropertyValue",
    name: "Job ID",
    value: fieldReference,
  },
  datePosted: created,
  validThrough: fieldDateEnd2?.value,
  employmentType: fieldJobContract?.name,
  hiringOrganization: {
    "@type": "Organization",
    name: "Curie",
    sameAs: "https://www.curie.fr",
    logo: {
      "@type": "ImageObject",
      url: config.public.frontendHost + "/_nuxt/assets/img/menu/logo-color.svg",
    },
  },
  jobLocation: {
    "@type": "Place",
    address: {
      "@type": "PostalAddress",
      addressCountry: "France",
      addressLocality: fieldJobLocation?.name,
    },
  },
}));

const formHidden = ref(true);

const changeFormStatus = (status) => {
  formHidden.value = status;
};

useCDNHeaders((helper) => {
  helper
    .private();
})
</script>

<style>
.JobContent {
  h2 {
    @apply font-lora font-normal text-h3Medium lg:text-h2Small;
  }
}
</style>
