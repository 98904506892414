import { gql } from "graphql-tag";

export const PublicationQuery = gql`
  query PublicationQuery($id: ID!, $langcode: Langcode!) {
    entityById(entityType: NODE, id: $id, langcode: $langcode) {
      id
      langcode
      ... on NodePublication {
        translations {
          pathRawField {
            list {
              alias
              langcode
            }
          }
        }
        fieldMetatag {
          value
        }
        fieldTitle
        fieldDate {
          value
        }
        fieldDigitalObjectIdentifier
        fieldAuthor
        fieldDescription
        fieldPublicationNewspaper {
          name
        }
        computedPublicationTeam {
          ... on NodeTeam {
            id
            fieldTitle
            pathRawField {
              list {
                alias
              }
            }
            fieldTeamType {
              name
            }
            fieldTeamMember {
              fieldTitle
              fieldFunction {
                name
              }
              fieldChief
              fieldPerson {
                id
                fieldFirstName
                fieldLastName
                pathRawField {
                  list {
                    alias
                  }
                }
                fieldImage {
                  alt
                  entity {
                    uriRawField {
                      list {
                        url
                      }
                    }
                  }
                }
                fieldLink {
                  uri {
                    path
                  }
                }
              }
            }
          }
        }
        fieldAuthorCurie {
          id
          fieldFirstName
          fieldLastName
          fieldPersonTitle {
            name
          }
          pathRawField {
            list {
              alias
            }
          }
          fieldImage {
            alt
            entity {
              uriRawField {
                list {
                  url
                }
              }
            }
          }
          fieldLink {
            uri {
              path
            }
            title
          }
        }
      }
    }
  }
`;
