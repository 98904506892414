import { useList } from "~/stores/list";
import useQuery from "~/composables/query";

import { isSameDay, formatDisplayDate, parseDate } from "~/utils/dateUtils";

import type { LocationQueryValue } from "vue-router";

export default function useDatepicker() {
  const route = useRoute();
  const { selectedDateRange } = storeToRefs(useList());
  const { updateQuery, hasDates } = useQuery();

  const isFullDay = computed(() =>
    isSameDay(selectedDateRange.value.start, selectedDateRange.value.end)
  );
  const displayableDate = computed(() =>
    formatDisplayDate(selectedDateRange.value, isFullDay.value)
  );

  const applyDates = () => {
    updateQuery({
      startDate: formatDateWithoutTimezone(selectedDateRange.value.start),
      endDate: formatDateWithoutTimezone(selectedDateRange.value.end),
    });
  };

  const clearDates = () =>
    updateQuery({ startDate: undefined, endDate: undefined });

  const setCalendarDates = (
    start: LocationQueryValue | LocationQueryValue[],
    end: LocationQueryValue | LocationQueryValue[]
  ) => {
    const defaultDate = new Date();
    selectedDateRange.value = {
      start: parseDate(start) ?? defaultDate,
      end: parseDate(end) ?? defaultDate,
    };
  };

  watch(
    () => ({ start: route.query.startDate, end: route.query.endDate }),
    ({ start, end }) => setCalendarDates(start, end),
    { immediate: true }
  );

  return {
    selectedDateRange,
    displayableDate,
    applyDates,
    clearDates,
    hasDates,
  };
}
