<template>
  <div class="py-7 border-grisClair lg:mt-20 border-y">
    <ul
      :class="{
        'justify-end': !prevItem && nextItem,
        'justify-start': !nextItem && prevItem,
        'justify-between': prevItem && nextItem,
      }"
      class="flex items-center w-full"
    >
      <li v-if="prevItem && prevItem.link" class="cursor-pointer">
        <a
          :href="prevItem.link"
          class="flex items-center font-bold font-raleway text-regular hover:text-orangeCurie"
        >
          <nuxt-icon name="chevronLeft" filled class="mt-1 mr-10 text-xl" />
          {{ truncate(prevItem.name, 40) }}
        </a>
      </li>
      <li v-if="prevItem && prevItem.id" class="cursor-pointer">
        <a
          @click.prevent="setSubMenu(prevItem)"
          class="flex items-center font-bold font-raleway text-regular hover:text-orangeCurie"
        >
          <nuxt-icon name="chevronLeft" filled class="mt-1 mr-10 text-xl" />
          {{ truncate(prevItem.name, 40) }}
        </a>
      </li>
      <li v-if="nextItem && nextItem.link" class="cursor-pointer">
        <a
          :href="nextItem.link"
          class="flex items-center font-bold font-raleway text-regular hover:text-orangeCurie"
        >
          {{ truncate(nextItem.name, 40) }}
          <nuxt-icon name="chevronRight" filled class="mt-1 ml-10 text-xl" />
        </a>
      </li>
      <li v-if="nextItem && nextItem.id" class="cursor-pointer">
        <a
          @click.prevent="setSubMenu(nextItem)"
          class="flex items-center font-bold font-raleway text-regular hover:text-orangeCurie"
        >
          {{ truncate(nextItem.name, 40) }}
          <nuxt-icon name="chevronRight" filled class="mt-1 ml-10 text-xl" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useTeamNavigation } from "@/composables/team/navigation";
import { truncate } from "@/utils/stringUtils";

import type { MenuItem, SubItem } from "@/interfaces/team";
import { onMounted } from "vue";

const props = defineProps({
  menuItems: Array as PropType<(MenuItem | SubItem)[]>,
  activeSection: String as PropType<string>,
  activeSubMenu: String as PropType<string>,
});

const emit = defineEmits(["set-sub-menu"]);
const { setSubMenu } = useTeamNavigation(emit);
const test = ref(0);
const currentItemIndex = computed(() => {
  if (props.activeSubMenu) {
    return props.menuItems?.findIndex((item) => "id" in item && item.id === props.activeSubMenu);
  }

  return props.menuItems?.findIndex((item) => "link" in item && item.link === props.activeSection);
});

const nextItem = computed(() => {
  return props.menuItems?.[test.value + 1] || null;
});

const prevItem = computed(() => {
  return props.menuItems?.[test.value - 1] || null;
});

watch(()=>currentItemIndex.value, 
()=>{
    test.value=currentItemIndex.value;
})

onMounted(() => {
  test.value=currentItemIndex.value;
});
</script>
