<script setup lang="ts">
import useQuery from "~/composables/query";

import type { Facet } from "~/interfaces/list";

const route = useRoute();
const { updateQuery } = useQuery();

defineProps({
  facet: {
    type: Object as PropType<Facet>,
    required: true,
  },
});

const today = ref(new Date());
const tomorrow = ref(new Date(today.value));
tomorrow.value.setDate(today.value.getDate() + 1);

const nextDecade = ref(new Date(today.value));
nextDecade.value.setFullYear(today.value.getFullYear() + 10);

const isUpcoming = computed(
  () =>
    route.query.startDate === formatDateWithoutTimezone(tomorrow.value) &&
    route.query.endDate === formatDateWithoutTimezone(nextDecade.value)
);

const handleChange = (event: Event) => {
  const isChecked = (event.target as HTMLInputElement).checked;

  if (isChecked) {
    return updateQuery({
      startDate: formatDateWithoutTimezone(tomorrow.value),
      endDate: formatDateWithoutTimezone(nextDecade.value),
    });
  }

  return updateQuery({ startDate: undefined, endDate: undefined });
};
</script>

<template>
  <ul class="flex flex-col gap-2.5 CheckboxFilter max-h-[180px] pt-2.5 overflow-y-auto pr-4">
    <li class="flex gap-2.5">
      <input
        v-model="isUpcoming"
        type="checkbox"
        id="upcoming-seminar"
        class="w-fit font-raleway text-regularMobile lg:text-regular shrink-0 relative top-[4px]"
        @change="handleChange($event)"
      />
      <label
        class="cursor-pointer font-raleway text-regularMobile lg:text-regular flex-auto flex justify-between"
        :class="{ 'font-bold text-orangeCurie': isUpcoming }"
        for="upcoming-seminar"
      >
        {{ $t("seminar.upcoming") }}
      </label>
    </li>
  </ul>
</template>
