<template>
  <div class="relative col-span-12 lg:col-span-4">
    <div
      id="fixedContent"
      class="col-span-12 lg:sticky top-[80px] lg:max-w-[400px] xl:max-w-[420px] 2xl:max-w-[490px] lg:z-0 border-b border-grisClair lg:border-b-0 pb-4 mb-10 lg:mb-4 lg:pb-0 lg:pr-20"
    >
      <h1
        v-if="Person.entityById.fieldFirstName && Person.entityById.fieldLastName"
        class="font-lora text-h3Medium lg:text-h2Small leading-[33px] lg:leading-[40px] mb-2"
      >
        {{ Person.entityById.fieldFirstName }}
        {{ Person.entityById.fieldLastName }}
      </h1>
      <div
        v-if="Person.entityById.fieldPersonTitle.length > 0"
        class="font-raleway text-regularMobile lg:text-regular leading-[25px] mb-2"
      >
        <span v-for="(personTitle, index) in Person.entityById.fieldPersonTitle" :key="index">
          {{ personTitle.name
          }}<span
            v-if="
              Person.entityById.fieldPersonTitle.length > 1 &&
              index != Object.keys(Person.entityById.fieldPersonTitle).length - 1
            "
            >,</span
          >
        </span>
      </div>
      <div
        v-if="Person.entityById.fieldPersonSite && Person.entityById.fieldPersonLocation"
        class="flex align-center font-raleway text-small"
      >
        {{ Person.entityById.fieldPersonSite.name }} -
        <nuxt-icon name="map-alt" filled class="flex items-center mx-0.5 text-regular" />
        {{ Person.entityById.fieldPersonLocation.name }}
      </div>
      <div class="flex gap-4 mt-2 align-center lg:mt-4">
        <a
          class="border w-[22px] h-[22px] flex items-center justify-center rounded border-grisFont hover:text-orangeCurie hover:border-orangeCurie"
          v-if="Person.entityById.fieldLink.length > 0"
          v-for="(link, index) in Person.entityById.fieldLink"
          :href="link.uri.path"
          :key="index"
          target="_blank"
        >
          <nuxt-icon v-if="link.title" :name="getSocialIcon(link.title)" class="cursor-pointer text-[14px]" />
        </a>
      </div>
      <div class="mt-4 lg:mt-6 lg:mb-6">
        <UiCustomImage
          v-if="Person.entityById?.fieldImage"
          :src="Person.entityById?.fieldImage?.entity.uriRawField.list[0].url"
          :alt="Person.entityById?.fieldImage?.alt"
          presets="person_side"
          customClass="w-full rounded rounded-1"
        />
        <NuxtImg v-else class="w-full rounded rounded-1" src="/image/default.png" presets="person_side" alt="Default" />
      </div>
      <div id="fixed" class="fixed mt-4 bottom-0 left-0 z-10 w-full lg:block lg:static">
        <UiButton
          :buttonLabel="$t('person.contact')"
          size="medium"
          :withIcon="false"
          class="w-full text-center lg:w-full"
          url="#contact"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTeamIcons } from "~/composables/team/icons";

defineProps({
  Person: Object,
});

const elementIsVisibleInViewport = () => {
  var el = document.querySelector("#contact");
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;
  // Partially visible elements return true:
  var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  var fixedEL = document.querySelector("#fixed");
  if (isVisible) {
    fixedEL.classList.remove("fixed");
  } else {
    fixedEL.classList.add("fixed");
  }
};

const { getSocialIcon } = useTeamIcons();

onMounted(() => {
  window.addEventListener("scroll", elementIsVisibleInViewport);
});
</script>
