<template>
  <div class="relative mb-8 w-full lg:px-4">
    <button
      id="dropdownDefaultButton"
      @click="toggleDropdown"
      class="inline-flex justify-between items-center p-3 w-full text-lg font-bold bg-white rounded-md border border-grisClair text-grisFont font-raleway"
      type="button"
    >
      {{ activeItemName }}
      <nuxt-icon v-show="!isDropdownOpen" name="chevronDown" filled />
      <nuxt-icon v-show="isDropdownOpen" name="chevronUp" filled />
    </button>
    <div v-show="isDropdownOpen" id="dropdown" class="absolute z-20 w-full bg-white rounded-md border border-grisClair">
      <ul>
        <li
          v-for="item in menuItems"
          :key="item.name"
          :class="item.link === activeSection ? 'font-bold' : 'font-normal'"
          class="block px-3 my-3 text-regularMobile lg:text-lg cursor-pointer font-raleway"
        >
          <div class="flex items-center justify-between">
            <a :href="item.link" @click="clickOnTopLevelItem(item)" class="w-full">{{ item.name }} </a>
            <NuxtIcon
              v-if="item.subMenu && item.subMenu.length > 0"
              :name="openedSection === item.link ? 'minus' : 'plus'"
              class="text-xl"
              @click="toggleSection(item.link)"
            />
          </div>
          <ul v-if="item.subMenu" v-show="openedSection === item.link" class="pl-4">
            <li
              v-for="subItem in item.subMenu"
              :key="subItem.name"
              @click="setSubMenu(subItem)"
              class="font-raleway text-md leading-[130%] text-grisFont hover:text-orangeCurie my-4"
              :class="subItem.id === activeSubMenu ? 'font-bold' : 'font-normal'"
            >
              <a :href="item.link" @click="closeDropdown()">{{ subItem.name }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useTeamNavigation } from "@/composables/team/navigation";

import type { MenuItem } from "@/interfaces/team";

const props = defineProps({
  menuItems: Array as PropType<MenuItem[]>,
  activeSection: String,
  activeSubMenu: String,
});

const emit = defineEmits(["set-sub-menu"]);
const { setSubMenu, toggleSection, openedSection} = useTeamNavigation(emit);

const isDropdownOpen: Ref<boolean> = ref(false);

const activeItemName = computed(() => {
  const activeSubItem = props.menuItems
    ?.flatMap((item) => item.subMenu || [])
    .find((subItem) => subItem.id === props.activeSubMenu);
  if (activeSubItem) return activeSubItem.name;

  return props.menuItems?.find((item) => item.link === props.activeSection)?.name || "";
});

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const closeDropdown = () => {
  isDropdownOpen.value = false;
};

const clickOnTopLevelItem = (item: MenuItem) => {
  if (item.subMenu) {
  } else {
    closeDropdown();
  }
};
</script>
