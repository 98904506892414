<script setup lang="ts">
import type { Facet } from "~/interfaces/list";

const props = defineProps({
  facet: {
    type: Object as PropType<Facet>,
    required: true,
  },
});

const facetsComponents = {
  Datepicker: resolveComponent("ListFacetsDatepicker"),
  Checkbox: resolveComponent("ListFacetsCheckbox"),
  Radio: resolveComponent("ListFacetsRadio"),
  SeminarType: resolveComponent("ListFacetsSeminarType"),
  Date: resolveComponent("ListFacetsDate"),
};

const isOpen = ref(false);
const searchTerm = ref("");

const isSearchable = computed(() => {
  return props.facet.type === "Checkbox" && props.facet.filters && props.facet.filters.length > 10 && isOpen.value;
});

const dynamicComponent = computed(() => facetsComponents[props.facet.type] || facetsComponents.Checkbox);
</script>

<template>
  <div :class="isOpen ? 'pb-6' : 'pb-4'" class="my-4 p-4 rounded border border-grisClair">
    <p
      class="flex justify-between items-center text-regularMobile lg:text-regular font-bold cursor-pointer font-raleway"
      :class="isOpen ? 'pb-[14px]' : ''"
      @click="isOpen = !isOpen"
    >
      {{ facet.label }}
      <span>
        <NuxtIcon :name="isOpen ? 'minus' : 'plus'" filled />
      </span>
    </p>
    <div v-if="isSearchable" class="relative w-full">
      <div class="flex border border-grisClair items-center rounded justify-between py-3 px-3.5 mb-3.5">
        <input
          v-model="searchTerm"
          :placeholder="$t('facet.filter.label.' + facet.field)"
          type="text"
          name="search"
          class="w-full focus:outline-none focus:border-0 peer-focus-visible:ring-0 font-raleway text-regularMobile lg:text-regularMobile text-grisFont placeholder:text-grisFont"
        />
        <button
          v-if="searchTerm"
          class="absolute right-8 top-0 h-full px-4 text-grisFont"
          type="button"
          @click.prevent="searchTerm = ''"
        >
          ×
        </button>
        <NuxtIcon name="search" filled class="text-h3Small" />
      </div>
    </div>
    <component v-if="isOpen" :is="dynamicComponent" :facet="facet" :searchTerm="searchTerm" />
  </div>
</template>
