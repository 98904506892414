<template>
  <div id="contact" class="pb-5 pt-[80px]">
    <h2
        class="pb-4 m-0 font-normal font-lora text-h3Medium lg:text-h2Small lg:pb-10"
    >
      {{ $t("person.contact") }}
      {{
        Person.entityById.fieldFirstName +
        " " +
        Person.entityById.fieldLastName
      }}
    </h2>
    <p
        class="font-raleway classic text-regularMobile lg:text-classic leading-[25px] pb-6 lg:pb-10"
    >
      {{ Person.entityById.fieldMessage }}
    </p>
    <div
        v-if="Person.entityById.fieldPersonPhone.length > 0"
        class="pb-6 font-raleway text-h3Small lg:text-medium lg:pb-10"
    >
      <div class="m-0 font-bold font-raleway text-regular lg:text-medium">
        {{ $t("person.phone") }}
      </div>
      <p
          v-for="(phone, index) in Person.entityById.fieldPersonPhone"
          :key="index"
          class="pt-4 text-classic text-regularMobile lg:text-regular"
      >
        {{ phone.fieldTitle }}: {{ phone.fieldPhone }} <br/>
      </p>
    </div>
    <div v-if="showSuccess == false" class="pb-6 m-0 font-bold font-raleway text-regular lg:text-medium lg:pb-5">
      {{ $t("person.message") }}
    </div>
    <!-- Formulaire Success message -->
    <div id="success" class="scroll-mt-20">
      <div
          class="mt-14 lg:mt-20 relative before:absolute before:content-[''] before:w-[calc(100%+32px)] lg:before:w-[calc(100%+80px)] before:top-[-40px] before:left-[-16px] lg:before:left-[-80px] before:bg-grisBackground before:h-[calc(100%+80px)] before:z-[-1]"
          v-if="showSuccess">
        <div class="m-0 mb-6 font-lora text-h3Medium lg:text-h2Small leading-[33px] lg:leading-[40px]">{{ $t("contact.sended") }}
        </div>
        <div class="font-raleway text-regularMobile lg:text-[1rem] leading-[25px] pb-6">
          <p>{{ $t("person_contact.success_intro") }}</p><br/>
          <p>{{ $t("person_contact.success_body") }}</p>
          <p>{{ $t("person_contact.success_end") }}</p>
        </div>
        <a href="#" @click="resend"
           class="flex items-center font-raleway font-bold text-grisFont underline text-regularMobile lg:text-regular hover:text-orangeCurie">
          {{ $t("person_contact.renew_message") }}
          <nuxt-icon name="arrowRight" filled class="ml-2"/>
        </a>
      </div>
    </div>
    <Form v-if="showSuccess == false" :validation-schema="schema" v-slot="{ errors }" @submit="onSubmitForm"
          class="m-w-[80%]">
      <div class="grid grid-cols-2 gap-4 mb-4 lg:mb-5">
        <Field
            type="hidden"
            name="reference"
            :value="Person.entityById.fieldRegistrationNumber"
        />
        <Field
            type="hidden"
            name="medecin"
            :value="`${Person.entityById.fieldFirstName} ${Person.entityById.fieldLastName}`"
        />
        <div class="flex flex-col col-span-2 lg:col-span-1">
          <label class="pb-2.5 font-bold font-raleway text-regular"
          >{{ $t("Name") }} <span class="text-red">*</span></label
          >
          <Field
              class="p-4 border border-grisClair font-regular font-raleway"
              type="text"
              name="last_name"
          />
          <span v-if="errors.last_name" class="pt-1 font-raleway text-small text-red">
                {{ errors.last_name }}
            </span>
        </div>
        <div class="flex flex-col col-span-2 lg:col-span-1">
          <label class="pb-2.5 font-bold font-raleway text-regular">
            {{ $t("Firstname") }} <span class="text-red">*</span>
          </label>
          <Field
              class="p-4 border border-grisClair font-regular font-raleway"
              type="text"
              name="first_name"
          />
          <span v-if="errors.first_name" class="pt-1 font-raleway text-small text-red">
                {{ errors.first_name }}
            </span>
        </div>

        <div class="flex flex-col col-span-2">
          <label class="pb-2.5 font-bold font-raleway text-regular"
          >{{ $t("Email") }} <span class="text-red">*</span></label
          >
          <Field
              class="p-4 border font-regular font-raleway"
              type="email"
              name="email"
          />
          <span v-if="errors.email" class="pt-1 font-raleway text-small text-red">
                {{ errors.email }}
            </span>
        </div>

        <div class="flex flex-col col-span-2">
          <label class="pb-2.5 font-bold font-raleway text-regular"
          >{{ $t("person_contact.subject_label") }} <span class="text-red">*</span></label
          >
          <Field
              class="p-4 border border-grisClair font-regular font-raleway"
              type="text"
              name="object"
          />
          <span v-if="errors.object" class="pt-1 font-raleway text-small text-red">
                {{ errors.message }}
            </span>
        </div>
        <div class="flex flex-col col-span-2">
          <label class="pb-2.5 font-bold font-raleway text-regular"
          >{{ $t("Message") }} <span class="text-red">*</span></label
          >
          <Field
              as="textarea"
              rows="10"
              class="p-4 border border-grisClair font-regular font-raleway"
              type="text"
              name="message"
          />
          <span v-if="errors.message" class="pt-1 font-raleway text-small text-red">
                {{ errors.message }}
            </span>
        </div>
      </div>
      <input type="submit"
             :value="$t('Send')"
             class="w-full bg-orangeCurie hover:bg-orangeHover text-white text-center lg:w-fit md:mb-0 mt-4 cursor-pointer inline-block text-lg leading-none font-bold font-raleway rounded py-4 px-6"/>
    </Form>
  </div>
</template>
<script setup lang="ts">
import {ref} from "vue";
import submitForm from "~/service/graphql/client/form/submit";
import upload from "~/service/graphql/client/form/upload";
import {Form, Field, defineRule, configure} from 'vee-validate';
import {required, email} from '@vee-validate/rules';
import {localize} from '@vee-validate/i18n';
import { useReCaptcha } from 'vue-recaptcha-v3';

const gtm = useGtm();

const FORM_ID = "contact_person";

const recaptchaInstance = useReCaptcha();
const recaptcha = async () => {
    // optional you can await for the reCaptcha load
    await recaptchaInstance?.recaptchaLoaded();
    // get the token, a custom action could be added as argument to the method
    const token = await recaptchaInstance?.executeRecaptcha("recaptcha_v3");
    return token;
};



const nuxtApp = useNuxtApp();

const props = defineProps({
  Person: Object
});

const showSuccess = ref(false);

const schema = {
  object: 'required',
  last_name: 'required',
  first_name: 'required',
  email: 'required|email',
  message: 'required',
};

interface PersonContactFormData {
  object: string;
  last_name: string;
  first_name: string;
  email: string;
  message: string;
  captcha_token: string;
  action: string;
}

const formData = ref(<PersonContactFormData>{
  object: null,
  last_name: null,
  first_name: null,
  email: null,
  message: null,
  captcha_token: null,
  action: null,
});

/** Validator part **/
defineRule('required', required);
defineRule('email', email);

configure({
  // Generates an English message locale generator
  generateMessage: localize('en', {
    messages: {
      required: 'This field is required',
      email: 'This email is not a valid email address',
    },
  }),
});

configure({
  // Generates an Frensh message locale generator
  generateMessage: localize('fr', {
    messages: {
      required: 'Ce champs est requis',
      email: 'Cette adresse e-mail n\'est pas reconnue',
    },
  }),
});


const onSubmitForm = async (values: PersonContactFormData) => {
  values.captcha_token = await recaptcha();
  values.action = "recaptcha_v3";
  const {data: formResult, error} = await submitForm(
      nuxtApp.$apollo.clients.default,
      FORM_ID,
      values
  );
  if (formResult.submitWebform.submission?.hasOwnProperty("id")) {
    var anchor = document.querySelector("#success");
    anchor?.scrollIntoView();
    showSuccess.value = true;

    triggerEvent();
  }
}

const triggerEvent = () => {
  gtm.trackEvent({
    event: 'contact_annuaire',
    contact_name: props.Person.entityById.fieldFirstName + " " + props.Person.entityById.fieldLastName,
    contact_job: props.Person.entityById.fieldPersonTitle[0].name
  })
}

const resend = (event) => {
  event.preventDefault();
  showSuccess.value = false;
}
</script>