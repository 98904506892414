<script setup lang="ts">
interface LinksProps {
  __typename: string;
  id: string;
  type: string;
  fieldLabelMenu: string;
  pathRawField: {
    __typename: string;
    list: [
      {
        __typename: string;
        alias: string;
      }
    ];
  };
}

const props = defineProps({
  links: {
    type: Array as PropType<LinksProps[]>,
    required: true,
  },
});

const route = useRoute();

const isActiveLink = (path: string): boolean => {
  return route.path === path;
};
</script>
<template>
  <div class="rounded border border-grisClair">
    <div
      class="border-b border-grisClair font-raleway font-bold text-[20px] text-grisFont px-4 py-2"
    >
      {{  $t("moreContent") }}
    </div>
    <ul>
      <li
        v-for="link in links"
        class="block px-3 my-3 text-lg font-normal cursor-pointer font-raleway"
        :class="
          isActiveLink(link.pathRawField.list[0].alias)
            ? 'text-grisFont/50'
            : 'text-grisFont'
        "
      >
        <UiCustomLink :path="link.pathRawField.list[0].alias">
          {{ link.fieldLabelMenu }}
        </UiCustomLink>
      </li>
    </ul>
  </div>
</template>
