<template>
    <div>
        <component v-if="routeStore.route?.meta?.bundle" :is="dynamicComponent(routeStore.route?.meta?.bundle)" />
        <Pages404 v-if="!routeStore.route?.meta?.bundle"></Pages404>
    </div>
</template>
<script setup lang="ts">
    import {getComponentByBundle} from '~/utils/pageResolver';
    import { useRouteStore } from '~/stores/route';
    const routeStore = useRouteStore();
    const route = useRoute();
    const nuxtApp = useNuxtApp();


    const getCurrentPage = () => {
        return nuxtApp.payload.drupalRoutes[route.path];
    }

    routeStore.setRoute(getCurrentPage());



    const pageComponents: Record<string, ReturnType<typeof resolveComponent>> = {
        cms: resolveComponent("PagesCms"),
        card_cancer: resolveComponent("PagesCardCancer"),
        news: resolveComponent("PagesNews"),
        news_list: resolveComponent("PagesNewsList"),
        contact: resolveComponent("PagesContact"),
        clinical_trial: resolveComponent("PagesClinicalTrial"),
        clinical_trial_list: resolveComponent("PagesClinicalTrialList"),
        hub_cancer: resolveComponent("PagesHub"),
        hub: resolveComponent("PagesHub"),
        hub_donation: resolveComponent("PagesHubDonation"),
        hub_treatment: resolveComponent("PagesHubTreatment"),
        job_offer: resolveComponent("PagesJobOffer"),
        job_offer_list: resolveComponent("PagesJobOfferList"),
        person: resolveComponent("PagesPerson"),
        person_list: resolveComponent("PagesPersonList"),
        publication: resolveComponent("PagesPublication"),
        publication_list: resolveComponent("PagesPublicationList"),
        seminar: resolveComponent("PagesSeminar"),
        seminar_list: resolveComponent("PagesSeminarList"),
        testimony: resolveComponent("PagesTestimony"),
        testimony_list: resolveComponent("PagesTestimonyList"),
        unit: resolveComponent("PagesUnit"),
        unit_list: resolveComponent("PagesUnitList"),
        team: resolveComponent("PagesTeam"),
        team_list: resolveComponent("PagesTeamList"),
    };
  
    const dynamicComponent = (bundle) => { 
        const component = pageComponents.cms;
        if(pageComponents[bundle]){
            return pageComponents[bundle];
        }
        return component;
    }
</script>
