<template>
  <div v-if="clinicalTrial?.entityById" class="lg:border-b lg:border-grisClair">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 border-b border-grisClair lg:border-0 pb-10 lg:pb-16">
        <div class="col-span-12 lg:col-span-7">
          <UiCustomLink
            v-if="backLink"
            :path="backLink"
            customClass="font-bold flex items-center font-raleway hover:text-orangeCurie text-regular mt-14 lg:mt-20 mb-[32px] lg:mb-10"
          >
            <nuxt-icon name="chevronLeft" filled class="mt-1 mr-4 text-xl" />
            {{ $t("clinical.backLink") }}
          </UiCustomLink>

          <span
              v-if="clinicalTrial.entityById.fieldClinicalTrialCancerType?.name"
            class="w-fit mb-2 rounded text-orangeCurie border border-orangeCurie font-raleway font-bold text-small py-1 px-1.5 inline-block"
            >{{ clinicalTrial.entityById.fieldClinicalTrialCancerType?.name }}</span
          >
          <h1 class="font-lora mt-2.5 mb-2.5 lg:mb-4 text-h3 lg:text-h1 leading-[45px] lg:leading-[57px]">
            {{ clinicalTrial.entityById.fieldTitle }}
          </h1>
          <div v-html="clinicalTrial.entityById.fieldIntroduction" class="font-lora leading-[30px] text-medium" />
        </div>
      </div>
    </div>
  </div>

  <div class="container mx-auto">
    <div class="grid grid-cols-12 mt-14 lg:mt-20 mb-14 lg:mb-[120px]">
      <div class="col-span-12 lg:col-start-3 lg:col-span-8 lg:pl-[110px] lg:border-l lg:border-grisClair">
        <div class="p-6 bg-grisBackground rounded">
          <div class="flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between lg:items-center">
            <ul class="flex flex-col gap-4 lg:gap-2">
              <li class="font-raleway text-regularMobile lg:text-regular">
                <span class="font-bold"
                  >{{
                    $t("clinical.openAtSince", { location: clinicalTrial.entityById.fieldClinicalTrialLocation1?.name })
                  }} </span
                >{{ formatDate(clinicalTrial.entityById.fieldDate.value) }}
              </li>
              <li
                v-if="clinicalTrial.entityById.fieldClinicalTrialLocation2 && clinicalTrial.entityById.fieldDate2"
                class="font-raleway text-regularMobile lg:text-regular"
              >
                <span class="font-bold"
                  >{{
                    $t("clinical.openAtSince", { location: clinicalTrial.entityById.fieldClinicalTrialLocation2?.name })
                  }} </span
                >{{ formatDate(clinicalTrial.entityById.fieldDate2.value) }}
              </li>
              <li v-if="targets" class="font-raleway text-regularMobile lg:text-regular">
                <span class="font-bold">{{ $t("clinical.target") }} : </span>{{ targets }}
              </li>
              <li class="font-raleway text-regularMobile lg:text-regular">
                <span class="font-bold">{{ $t("clinical.phase") }} : </span
                >{{ clinicalTrial.entityById.fieldClinicalTrialPhase.name }}
              </li>
            </ul>

            <NuxtImg
              v-if="Number(clinicalTrial.entityById.fieldPromote)"
              class="rounded w-[188px]"
              src="/image/institut.svg"
              alt="Image Donation"
            />
          </div>
        </div>
        <div class="pt-14 lg:pt-20 pb-14 lg:pb-20 lg:border-b lg:border-grisClair">
          <h2
            class="font-lora font-normal m-0 leading-[33px] lg:leading-[40px] pb-6 lg:pb-10 text-h3Medium lg:text-h2Small"
          >
            {{ $t("clinical.trialDescription") }}
          </h2>
          <div
            v-html="clinicalTrial.entityById.fieldDescription"
            class="font-raleway leading-[25px] text-regularMobile lg:text-regular"
          />

          <UiButton
            v-if="clinicalTrial.entityById.fieldLink[0]?.uri?.path"
            :url="clinicalTrial.entityById.fieldLink[0]?.uri?.path"
            :buttonLabel="clinicalTrial.entityById.fieldLink[0]?.title"
            :withIcon="true"
            target="_blank"
            size="large"
            class="w-full justify-center lg:w-fit mt-6 lg:mt-10 text-center flex flex-row-reverse"
            iconName="linkExternal"
            iconClass="ml-2.5 mr-0"
          />
        </div>
        <div v-if="personArray.length > 0">
          <h2
            class="font-lora font-normal m-0 leading-[33px] lg:leading-[40px] pb-6 lg:pb-10 lg:pt-10 text-h3Medium lg:text-h2Small border-b border-grisClair"
          >
            {{ $t("clinical.mainInvestigators", personArray.length) }}
          </h2>

          <div v-for="person in personArray" class="flex flex-col">
            <div
              class="flex flex-row-reverse justify-end lg:justify-normal gap-4 lg:gap-6 lg:flex-row py-6 lg:py-4 border-b border-b-grisClair group"
            >
              <div class="flex flex-col gap-2 overflow-hidden lg:basis-[62%]">
                <h3 class="m-0 font font-lora text-medium lg:text-h3Small">
                  <UiCustomLink :path="person.pathRawField.list[0].alias" customClass="hover:text-orangeCurie">
                    {{ person.fieldFirstName }} {{ person.fieldLastName }}
                  </UiCustomLink>
                </h3>
                <p v-if="person.fieldPersonTitle?.length" class="font-raleway text-regular leading-[25px]">
                  {{ getPersonTitle(person) }}
                </p>
                <UiCustomLink
                  :path="person.pathRawField.list[0].alias"
                  customClass="font-raleway w-fit text-regularMobile lg:text-regular underline hover:text-orangeCurie cursor-pointer"
                >
                  {{ $t("person.contact") }}
                </UiCustomLink>
              </div>
              <div class="lg:ml-auto overflow-hidden">
                <UiCustomImage
                  v-if="person.fieldImage"
                  :src="person.fieldImage.entity.uriRawField.list[0].url"
                  :alt="person.fieldImage.alt"
                  presets="person_list"
                  customClass="rounded object-cover"
                />
                <NuxtImg
                    v-else
                    class="rounded object-cover"
                    presets="person_list"
                    src="/image/default.png"
                    alt="Default"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import { useBackLink } from "~/composables/backlink";
import getClinicalTrial from "~/service/graphql/client/clinical-trial";

import type { ClinicalTrial, NodePerson } from "~/interfaces/clinicalTrial";
import type { SeoTags } from "~/interfaces/seo";

import {useContextStore} from "~/stores/context";

import {filterArray} from '~/utils/filterDataUtils';
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();

const contextStore = useContextStore();

const config = useRuntimeConfig();
const route = useRoute();
const langcode = config.public.langcode;

const { getBackLink } = useBackLink();
const backLink = getBackLink();

const { data: clinicalTrial } = (await getClinicalTrial(routeStore.route?.name?.toString()!, langcode)) as {
  data: Ref<ClinicalTrial>;
};
contextStore.setContent(clinicalTrial.value?.entityById);

const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(clinicalTrial.value?.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? clinicalTrial.value?.entityById?.fieldTitle);
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? clinicalTrial.value?.entityById?.fieldIntroduction,
    backendMetatags?.title ?? clinicalTrial.value?.entityById?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

const targets = computed(() =>
  clinicalTrial.value?.entityById.fieldClinicalTrialTarget?.map((target) => target.name).join(", ")
);

const getPersonTitle = (person: NodePerson) => {
  return person.fieldPersonTitle?.map((title) => title.name).join(", ");
};


const personArray = filterArray(clinicalTrial.value?.entityById?.fieldPerson);


useCDNHeaders((helper) => {
  helper
    .private();
})
</script>
