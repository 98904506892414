import { gql } from "graphql-tag";

export const SeminarQuery = gql`
  query SeminarQuery($id: ID!, $langcode: Langcode!) {
    entityById(entityType: NODE, id: $id, langcode: $langcode) {
      id
      langcode
      ... on NodeSeminar {
        translations {
          pathRawField {
            list {
              alias
              langcode
            }
          }
        }
        fieldMetatag {
          value
        }
        fieldTitle
        fieldDateBegin {
          value
        }
        fieldDateEnd {
          value
        }
        fieldSite
        fieldRoom
        fieldAddress
        fieldDescription
        fieldOrganizer {
          fieldFirstName
          fieldLastName
          fieldEmail
          fieldInstitute
          fieldStructure
          fieldTitle
        }
        fieldSpeaker {
          fieldFirstName
          fieldLastName
          fieldEmail
          fieldInstitute
          fieldStructure
          fieldTitle
        }
        fieldInvitingPersons {
          fieldFirstName
          fieldLastName
          fieldEmail
          fieldInstitute
          fieldStructure
          fieldTitle
        }
        fieldContact {
          fieldFirstName
          fieldLastName
          fieldEmail
          fieldInstitute
          fieldStructure
          fieldTitle
        }
      }
    }
  }
`;
