<script setup lang="ts">
import useDatepicker from "~/composables/datepicker";

import type { Facet } from "~/interfaces/list";

const { selectedDateRange, displayableDate, applyDates, clearDates, hasDates } = useDatepicker();

defineProps({
  facet: {
    type: Object as PropType<Facet>,
    required: true,
  },
});

const config = useRuntimeConfig();
const locale = config.public.locale;
</script>

<template>
  <div
    class="w-full rounded flex justify-between items-center mb-4 lg:mb-6 px-3 py-[13px] border border-grisClair focus:outline-0"
  >
    <input
      type="text"
      v-model="displayableDate"
      class="font-raleway w-full text-regularMobile lg:text-regular lg:w-full"
      readonly
    />
    <nuxt-icon class="text-[24px]" name="calendar" filled />
  </div>
  <client-only>
    <div class="flex flex-col">
      <VDatePicker :locale="locale" class="VCalendar" borderless color="orange" v-model.range="selectedDateRange" />
      <div
        class="flex justify-end lg:justify-center gap-[30px] mt-4 lg:mt-7 font-raleway font-bold text-regularMobile lg:text-regular"
      >
        <button class="underline hover:text-orangeCurie" @click="applyDates">
          {{ $t("list.datePicker.applyDates") }}
        </button>
        <button class="underline hover:text-orangeCurie" v-if="hasDates" @click="clearDates">
          {{ $t("list.datePicker.eraseDates") }}
        </button>
      </div>
    </div>
  </client-only>
</template>

<style>
.VCalendar {
  width: 100%;

  .vc-highlight-bg-solid {
    border-radius: inherit;
  }
  .vc-header {
    @apply mb-4 mt-0;
  }
  .vc-title-wrapper {
    @apply font-raleway text-regularMobile lg:text-regular;
  }

  .vc-weekday {
    @apply font-raleway text-small font-bold text-grisFont;
  }

  .vc-day {
    @apply font-raleway text-regular lg:text-regularMobile font-normal;
  }

  .vc-day-content {
    @apply font-normal;
  }
}

.vc-monthly .is-not-in-month {
  background-color: rgba(247, 247, 247, 0.7);

  * {
    color: #353537;
    opacity: 0.4;
  }
}

.vc-orange {
  --vc-accent-50: rgba(249, 180, 121, 0.3);
  --vc-accent-100: rgba(249, 180, 121, 0.3);
  --vc-accent-200: rgba(249, 180, 121, 0.3);
  --vc-accent-300: #f58220;
  --vc-accent-400: #f58220;
  --vc-accent-500: #f58220;
  --vc-accent-600: #f58220;
  --vc-accent-700: #f58220;
  --vc-accent-800: #f58220;
  --vc-accent-900: #f58220;
}
</style>
