import { gql } from "graphql-tag";

export const TeamQuery = gql`query TeamQuery($id: ID!, $langcode: Langcode!) {
  entityById(entityType: NODE, id: $id, langcode: $langcode) {
    ... on NodeTeam {
      id
      langcode
      translations {
        pathRawField {
          list {
            alias
            langcode
          }
        }
      }
      fieldMetatag {
        value
      }
      fieldTeamType {
        id
        name
      }
      computedTeamRelatedUnit {
        ... on NodeUnit {
          id
          fieldTitle
          fieldUnitType {
            name
          }
          pathRawField {
            list {
              alias
            }
          }
        }
      }
      fieldPartners {
        id
        name
        fieldLink {
          uri {
            path
          }
        }
        fieldLogo {
          alt
          title
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
      }
      fieldOtherPartners {
        id
        name
        fieldLink {
          uri {
            path
          }
        }
        fieldLogo {
          alt
          title
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
      }
      fieldTitle
      fieldIntroduction
      fieldImage {
        alt
        entity {
          uriRawField {
            list {
              url
            }
          }
        }
      }
      fieldSeminars{
        id
        langcode
        entityBundle
        ... on NodeSeminar {
          fieldType
          fieldTitle
          fieldDateBegin {
            value
          }
          fieldDateEnd {
            value
          }
          pathRawField {
            list {
              alias
            }
          }
          fieldSpeaker {
            fieldFirstName
            fieldLastName
          }
          fieldSite
          fieldRoom
        }
      }
      fieldPresentation {
        ...wysiwygParagraph
        ...visualLegendParagraph
        ...videoLegendParagraph
        ...titleParagraph
        ...taglineParagraph
        ...quotationParagraph
        ...keyFigureParagraph
        ...collapsibleParagraph
        ...carouselPersonsParagraph
        ...bannerImageParagraph
        ...columnParagraph
        ...greyBackgroundParagraph
        ...pushParagraph
        ...sourceParagraph
        ...textImageParagraph
        ...donationParagraph
        ...testimonyParagraph
        ...logoParagraph
        ...thumbnailParagraph
        ...linkParagraph
        ...newsParagraph
        ...carouselTestimonyParagraph

        #NEW SPECIFIC PARAGRAPH ON THIS PAGE
        ...documentParagraph
      }
      fieldTextScientificAxis
      fieldScientificAxis {
        id
        name
        fieldLink {
          uri {
            path
          }
        }
      }
      fieldTeamMember {
        fieldTitle
        fieldFunction {
          id
          name
        }
        fieldImage {
          alt
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
        fieldLinks {
          uri {
            path
          }
        }
        fieldChief
        fieldPerson {
          id
          fieldLastName
          fieldFirstName
          fieldPersonTitle {
            name
          }
          fieldImage {
            alt
            entity {
              uriRawField {
                list {
                  url
                }
              }
            }
          }
          fieldLink {
            title
            uri {
              path
            }
          }
          pathRawField {
            list {
              alias
              langcode
            }
          }
        }
      }
      fieldTeamMemberOld {
        fieldTitle
        fieldFunction {
          name
        }
      }
      fieldTitleContent1
      fieldIntroductionContent1
      fieldTeamTab1 {
        fieldTitle
        fieldTeamParagraphs {
          ...wysiwygParagraph
          ...visualLegendParagraph
          ...videoLegendParagraph
          ...titleParagraph
          ...taglineParagraph
          ...quotationParagraph
          ...keyFigureParagraph
          ...collapsibleParagraph
          ...carouselPersonsParagraph
          ...bannerImageParagraph
          ...columnParagraph
          ...greyBackgroundParagraph
          ...pushParagraph
          ...sourceParagraph
          ...textImageParagraph
          ...donationParagraph
          ...testimonyParagraph
          ...logoParagraph
          ...thumbnailParagraph
          ...linkParagraph
          ...newsParagraph
          ...carouselTestimonyParagraph

          ...documentParagraph
        }
      }
      fieldTitleContent2
      fieldIntroductionContent2
      fieldTeamTab2 {
        fieldTitle
        fieldTeamParagraphs {
          ...wysiwygParagraph
          ...visualLegendParagraph
          ...videoLegendParagraph
          ...titleParagraph
          ...taglineParagraph
          ...quotationParagraph
          ...keyFigureParagraph
          ...collapsibleParagraph
          ...carouselPersonsParagraph
          ...bannerImageParagraph
          ...columnParagraph
          ...greyBackgroundParagraph
          ...pushParagraph
          ...sourceParagraph
          ...textImageParagraph
          ...donationParagraph
          ...testimonyParagraph
          ...logoParagraph
          ...thumbnailParagraph
          ...linkParagraph
          ...newsParagraph
          ...carouselTestimonyParagraph

          ...documentParagraph
        }
      }
      fieldTitleContent3
      fieldIntroductionContent3
      fieldTeamTab3 {
        fieldTitle
        fieldTeamParagraphs {
          ...wysiwygParagraph
          ...visualLegendParagraph
          ...videoLegendParagraph
          ...titleParagraph
          ...taglineParagraph
          ...quotationParagraph
          ...keyFigureParagraph
          ...collapsibleParagraph
          ...carouselPersonsParagraph
          ...bannerImageParagraph
          ...columnParagraph
          ...greyBackgroundParagraph
          ...pushParagraph
          ...sourceParagraph
          ...textImageParagraph
          ...donationParagraph
          ...testimonyParagraph
          ...logoParagraph
          ...thumbnailParagraph
          ...linkParagraph
          ...newsParagraph
          ...carouselTestimonyParagraph

          ...documentParagraph
        }
      }
      fieldPublications {
        id
        fieldTitle
        fieldDate {
          value
        }
        fieldPublicationNewspaper {
          name
        }
        fieldAuthor
        fieldAuthorCurie {
          fieldFirstName
          fieldLastName
          pathRawField {
            list {
              alias
            }
          }
        }
        pathRawField {
          list {
            alias
          }
        }
      }
      fieldLifeTeam {
        ...wysiwygParagraph
        ...visualLegendParagraph
        ...videoLegendParagraph
        ...titleParagraph
        ...taglineParagraph
        ...quotationParagraph
        ...keyFigureParagraph
        ...collapsibleParagraph
        ...carouselPersonsParagraph
        ...bannerImageParagraph
        ...columnParagraph
        ...greyBackgroundParagraph
        ...pushParagraph
        ...sourceParagraph
        ...textImageParagraph
        ...donationParagraph
        ...testimonyParagraph
        ...logoParagraph
        ...thumbnailParagraph
        ...linkParagraph
        ...newsParagraph
        ...carouselTestimonyParagraph
      }
      computedNewsTeam {
        ... on NodeNews {
          id
          pathRawField {
            list {
              alias
            }
          }
          fieldSubtitle
          fieldTitle
          fieldDate {
            value
          }
          fieldImage {
            alt
            entity {
              uriRawField {
                list {
                  url
                }
              }
            }
          }
        }
      }
      fieldIntroductionJoinUs
      computedTeamUnitRelatedJob {
        ... on NodeJobOffer {
          id
          fieldJobContract {
            name
          }
          fieldJobLocation {
            name
          }
          fieldTitle
          fieldDateEnd2 {
            value
          }
          fieldJobSector {
            name
          }
          path {
            alias
            langcode
          }
        }
      }
      fieldSites {
        id
        name
        fieldLink {
          uri {
            path
          }
        }
        fieldLogo {
          alt
          title
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
      }
      fieldFooter {
        fieldParagraph {
          ...bannerImageParagraph
          ...donationParagraph
          ...pushParagraph
          ...testimonyParagraph
        }
      }
    }
  }
}

fragment documentParagraph on ParagraphDocument {
  id
  type: entityBundle
  fieldTitle
  fieldDocument {
    fieldTitle
    fieldImage {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldFile {
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
  }
}

fragment wysiwygParagraph on ParagraphWysiwyg {
  id
  type: entityBundle
  fieldAnchor
  fieldBody
}

fragment visualLegendParagraph on ParagraphVisualLegend {
  id
  type: entityBundle
  fieldAnchor
  fieldImage {
    alt
    entity {
      uriRawField {
        list {
          url
        }
      }
    }
  }
  fieldLegend
}

fragment videoLegendParagraph on ParagraphVideoLegend {
  id
  type: entityBundle
  fieldAnchor
  fieldVideo
  fieldLegend
}

fragment titleParagraph on ParagraphTitle {
  id
  type: entityBundle
  fieldAnchor
  fieldTitle
  fieldTitleLevel
}

fragment taglineParagraph on ParagraphTagline {
  id
  type: entityBundle
  fieldAnchor
  fieldTagline
}

fragment quotationParagraph on ParagraphQuotation {
  id
  type: entityBundle
  fieldAnchor
  fieldQuotation
  fieldAuthor
  fieldJob
}

fragment keyFigureParagraph on ParagraphKeyFigure {
  id
  type: entityBundle
  fieldTitle
  fieldSubtitle
  fieldIntroduction
  fieldAnchor
  fieldKeyFigureItem {
    ... on ParagraphKeyFigureItem {
      id
      type: entityBundle
      fieldNumber
      fieldText
    }
  }
}

fragment collapsibleParagraph on ParagraphCollapsible {
  id
  type: entityBundle
  fieldAnchor
  fieldCollapsibleItem {
    ... on ParagraphCollapsibleItem {
      id
      type: entityBundle
      fieldQuestion
      fieldAnswer
    }
  }
}

fragment carouselPersonsParagraph on ParagraphCarouselPersons {
  id
  type: entityBundle
  fieldAnchor
  fieldPersons {
    fieldFirstName
    fieldLastName
    fieldPersonTitle {
      name
    }
    fieldImage {
      alt
      title
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
    pathRawField {
      list {
        alias
      }
    }
  }
}

fragment bannerImageParagraph on ParagraphBannerImage {
  id
  type: entityBundle
  fieldAnchor
  fieldBannerImageItem {
    fieldTitle
    fieldText
    fieldLink {
      title
      uri {
        path
      }
    }
    fieldImage {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldImageMobile {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
  }
}

fragment greyBackgroundParagraph on ParagraphGreyBackground {
  id
  type: entityBundle
  fieldAnchor
  fieldBody
}

fragment columnParagraph on ParagraphColumns {
  id
  type: entityBundle
  fieldAnchor
  fieldTitle
  fieldColomnsItem {
    id
    fieldTitle
    fieldBody
    fieldImage {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
  }
}

fragment pushParagraph on ParagraphPush {
  id
  type: entityBundle
  fieldAnchor
  fieldPushItem {
    fieldImage {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldSubtitle
    fieldTitle
    fieldBody
  }
}

fragment sourceParagraph on ParagraphSource {
  id
  type: entityBundle
  fieldAnchor
  fieldBody
}

fragment textImageParagraph on ParagraphTextImage {
  id
  type: entityBundle
  fieldAnchor
  fieldImage {
    alt
    entity {
      uriRawField {
        list {
          url
        }
      }
    }
  }
  fieldPositionImage
  fieldSubtitle
  fieldTitle
  fieldBody
}

fragment linkParagraph on ParagraphLink {
  id
  type: entityBundle
  fieldAnchor
  fieldTitle
  fieldBody
  fieldLink {
    title
    uri {
      path
    }
  }
  fieldColumnNumber
  fieldLinkItem {
    id
    fieldImage {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldLink {
      title
      uri {
        path
      }
    }
  }
}

fragment thumbnailParagraph on ParagraphThumbnail {
  id
  type: entityBundle
  fieldAnchor
  fieldTitle
  fieldSubtitle
  fieldBody
  fieldImages {
    alt
    title
    entity {
      uriRawField {
        list {
          url
        }
      }
    }
  }
}

fragment logoParagraph on ParagraphLogo {
  id
  type: entityBundle
  fieldAnchor
  fieldTitle
  fieldSubtitle
  fieldBody
  fieldImages {
    alt
    title
    entity {
      uriRawField {
        list {
          url
        }
      }
    }
  }
  fieldLink {
    title
    uri {
      path
    }
  }
}

fragment testimonyParagraph on ParagraphTestimony {
  id
  type: entityBundle
  fieldAnchor
  fieldImage {
    alt
    entity {
      uriRawField {
        list {
          url
        }
      }
    }
  }
  fieldSubtitle
  fieldText
  fieldFirstName
  fieldAge
  fieldLink {
    title
    uri {
      path
    }
  }
}

fragment donationParagraph on ParagraphDonation {
  id
  type: entityBundle
  fieldAnchor
  fieldTitle
}

fragment carouselTestimonyParagraph on ParagraphCarrouselTestimony {
  ... on ParagraphCarrouselTestimony {
    id
    fieldAnchor
    fieldTitle
    fieldTestimony {
      pathRawField {
        list {
          alias
        }
      }
      fieldImage {
        alt
        entity {
          uriRawField {
            list {
              url
            }
          }
        }
      }
      fieldFirstName
      fieldCharacteristic
      fieldVerbatim
    }
  }
}

fragment newsParagraph on ParagraphNews {
  id
  type: entityBundle
  fieldAnchor
  fieldDomainNews {
    fieldTitle
  }
  computedParagraphNewsDomain {
    ... on NodeNews {
      id
      pathRawField {
        list {
          alias
        }
      }
      fieldTitle
      fieldDate {
        value
      }
      fieldIntroductionNews
      fieldImage {
        alt
        entity {
          uriRawField {
            list {
              url
            }
          }
        }
      }
    }
  }
}
`;
