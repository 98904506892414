import { TeamQuery } from "../../query/content/Team";
import type { TeamItems } from "~/interfaces/team";
import { getTeamCacheTag } from "~/utils/cacheTags";

export default async function getTeam(id: string, langcode: string) {
  const key = `page--${id}`;
  const { data: team } = await useAsyncData(key, async () => {
    const { value, addToCache } = await useDataCache(key)
    if (value) {
      return value
    }
    const { data: response } = await useAsyncQuery<TeamItems>(TeamQuery, { id, langcode });
  
    const TAGS : string[] = getTeamCacheTag(response._value);
    await addToCache(response._value, TAGS);
    return response._value;
  })
  return {data: team._value };
}
