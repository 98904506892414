<script setup lang="ts">
interface ListItemProps {
  __typename: string;
  id: string;
  type: string;
  fieldLabelMenu: string;
  pathRawField: {
    __typename: string;
    list: [
      {
        __typename: string;
        alias: string;
      }
    ];
  };
}

const props = defineProps({
  listItems: {
    type: Array as PropType<ListItemProps[]>,
    required: true,
  },
});

const route = useRoute();
const isDropdownOpen: Ref<boolean> = ref(false);

const currentLabel = computed(() => {
  const currentPath = route.path;
  const currentItem = props.listItems.find((item) =>
    item.pathRawField.list.some((alias) => alias.alias === currentPath)
  );
  return currentItem ? currentItem.fieldLabelMenu : "Traitements";
});

function toggleDropdown(): void {
  isDropdownOpen.value = !isDropdownOpen.value;
}

function closeDropdown(): void {
  isDropdownOpen.value = false;
}
</script>
<template>
  <div class="relative w-full">
    <button
      id="dropdownDefaultButton"
      @click="toggleDropdown"
      class="font-raleway font-bold text-regularMobile lg:text-regular inline-flex items-center justify-between w-full p-3 text-lg bg-white border border-grisClair rounded text-grisFont font-raleway"
      type="button"
    >
      {{ currentLabel }}
      <nuxt-icon v-show="!isDropdownOpen" name="chevronDown" filled />
      <nuxt-icon v-show="isDropdownOpen" name="chevronUp" filled />
    </button>
    <div
      v-show="isDropdownOpen"
      id="dropdown"
      class="absolute z-20 w-full bg-white border border-grisClair rounded"
      @click="closeDropdown"
    >
      <ul
        class="py-2 text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="dropdownDefaultButton"
      >
        <li
          v-for="item in listItems"
          :key="item.id"
          class="block px-3 my-3 text-lg font-normal cursor-pointer text-grisFont font-raleway hover:text-orangeCurie hover:font-bold"
        >
          <UiCustomLink :path="item.pathRawField.list[0].alias">
            {{ item.fieldLabelMenu }}
          </UiCustomLink>
        </li>
      </ul>
    </div>
  </div>
</template>
