<template>
    <!-- Fonctions Start -->
    <div class="border-b pb-[38px] border-grisClair">
        <h2 class="pb-8 mb-0 font-normal font-lora text-h3Medium lg:text-h2Small lg:pb-10">
            {{ $t("person.functionTitle") }}
        </h2>

        <div v-for="(PersonFunction, index) in PersonFunctions"
            :key="index"
             :class="{
        'border-b border-grisClair': index != Object.keys(PersonFunctions).length - 1 && Object.keys(PersonFunctions).length != 1,
      }"
            class="flex flex-col mb-4 lg:mb-6 relative before:content-[''] before:absolute before:left-0 before:bottom-[16px] before:h-[85%] before:border before:border-orangeCurie pl-4 pb-4 lg:pb-[18px] lg:pl-6">
            
            <h3 class="pb-2 m-0 font-bold font-raleway text-regular lg:text-medium">
                {{ PersonFunction.field_function }}
            </h3>
            
            <div v-if="PersonFunction.field_structure"
                class="pb-2 font font-raleway text-regularMobile lg:text-regular">
                    {{ PersonFunction.field_structure }}
            </div>

            <div v-if="PersonFunction.field_team.field_title" class="flex flex-wrap items-center pb-2">
                <p class="font font-raleway text-regularMobile lg:text-regular mr-1">
                    {{ $t("team.team") }} : 
                </p>

                <UiCustomLink :path="PersonFunction.field_team.path" class="font-raleway text-regularMobile lg:text-regular underline hover:text-orangeCurie cursor-pointer" >
                    {{ PersonFunction.field_team.field_title.value }}
                </UiCustomLink>
            </div>

            <div v-if="PersonFunction.field_unit.field_title" class="flex flex-wrap items-center pb-2">
                <p class="font font-raleway text-regularMobile lg:text-regular mr-1">
                    {{ $t("team.unit") }} : 
                </p>

                <UiCustomLink :path="PersonFunction.field_unit.path" class="font-raleway text-regularMobile lg:text-regular underline hover:text-orangeCurie cursor-pointer" >
                    {{ PersonFunction.field_unit.field_title.value }}
                </UiCustomLink>
            </div>
        </div>

    </div>
</template>

<script setup lang="ts">
    const props = defineProps({
        Person: Object
    });

    const PersonFunctions = computed(() => {
        const personFunctionString = props.Person.entityById.computedPersonFunction[0];
        personFunctionString.replace('/', '');
        const personFunctions = JSON.parse(personFunctionString);
        //console.log(personFunctions);

        return personFunctions;
    });
</script>