<template>
  <div
    class="mt-[50px] lg:mt-20 lg:border-grisClair lg:border-b field-header"
    :class="fieldHeaderData.fieldImage ? '' : 'border-b border-grisClair'"
  >
    <div class="container mx-auto">
      <div
        class="grid grid-cols-1"
        :class="
          fieldHeaderData.fieldImage ? 'grid lg:grid-cols-12 mb-4 lg:mb-0 gap-[27px] xl:gap-20' : 'lg:grid-cols-12'
        "
      >
        <div
          class="mb-10 lg:mb-16 flex flex-col justify-end"
          :class="fieldHeaderData.fieldImage ? 'col-span-1 lg:col-span-5' : 'col-span-1 lg:col-span-7'"
        >
          <div v-if="showBackLink" class="mb-[32px] lg:mb-10">
            <UiCustomLink
              v-if="backLink"
              :path="backLink"
              customClass="font-bold flex items-center font-raleway hover:text-orangeCurie text-regular"
            >
              <nuxt-icon name="chevronLeft" filled class="mt-1 mr-4 text-xl" />
              {{ $t("news.backToList") }}
            </UiCustomLink>
          </div>
          <h1
            v-if="!isHubCancerPage"
            class="font-lora font-normal text-h3 lg:text-h1 leading-[45px] lg:leading-[57px] mb-4"
            :class="isListPage ? '' : 'mb-10 lg:mb-11'"
          >
            {{ customTitle ?? fieldHeaderData.fieldTitle }}
          </h1>
          <p
            v-if="isHubCancerPage"
            class="font-lora font-normal text-h3 lg:text-h1 leading-[45px] lg:leading-[57px] mb-10"
          >
            {{ fieldHeaderData.fieldTitle }}
          </p>
          <div
            v-if="fieldHeaderData.fieldIntroduction2 && showIntroduction"
            v-html="fieldHeaderData.fieldIntroduction2"
            :class="isListPage ? 'wysiwyg-list mb-2' : 'wysiwyg'"
          />
          <div
            v-if="fieldHeaderData.fieldTitlePush || fieldHeaderData.fieldTextPush || fieldHeaderData.fieldLinkPush"
            class="inline-block w-full rounded-md lg:w-fit font-raleway"
            :class="
              fieldHeaderData.fieldTitlePush || fieldHeaderData.fieldTextPush ? 'border border-grisClair p-6' : ''
            "
          >
            <div v-if="fieldHeaderData.fieldTitlePush" class="text-lg font-bold">
              {{ fieldHeaderData.fieldTitlePush }}
            </div>
            <div
              v-if="fieldHeaderData.fieldTextPush"
              class="text-lg font-normal"
              v-html="fieldHeaderData.fieldTextPush"
            />
            <UiButton
              v-if="fieldHeaderData.fieldLinkPush?.title && fieldHeaderData.fieldLinkPush?.uri?.path"
              :buttonLabel="fieldHeaderData.fieldLinkPush.title"
              :url="fieldHeaderData.fieldLinkPush.uri.path"
              size="large"
              :withIcon="false"
              class="w-full mt-4 text-center lg:w-auto"
            />
          </div>
        </div>
        <div
          v-if="fieldHeaderData.fieldImage"
          class="flex mx-[-15px] col-span-1 lg:col-span-6 lg:col-start-7 items-end"
        >
          <UiCustomImage
            v-if="showImage && fieldHeaderData.fieldImage"
            :src="fieldHeaderData.fieldImage.entity.uriRawField.list[0].url"
            :alt="fieldHeaderData.fieldImage.alt"
            presets="field_header"
            customClass="w-full object-cover rounded lg:rounded-none lg:rounded-tl lg:rounded-tr lg:ml-auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBackLink } from "~/composables/backlink";

import type { ParagraphHeader } from "@/interfaces/paragraph";

interface Props {
  fieldHeaderData: ParagraphHeader;
  isListPage?: boolean;
  isHubCancerPage?: boolean;
  customTitle?: string;
  showIntroduction?: boolean;
  showImage?: boolean;
  showBackLink?: boolean;
}

withDefaults(defineProps<Props>(), {
  showIntroduction: true,
  showBackLink: false,
  showImage: true,
});

const { getBackLink } = useBackLink();
const backLink = getBackLink("news_list");
</script>
