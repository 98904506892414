import { normalizeString } from "~/utils/stringUtils";
import type { FieldTeamMember } from "~/interfaces/team";

export const useTeamIcons = () => {
  const memberHasSocials = (member: FieldTeamMember) => {
    return member.fieldPerson?.fieldLink?.length > 0;
  };

  const icons = {
    facebook: "Facebook",
    linkedin: "LinkedIn",
    twitter: "Twitter",
    orcid: "Orcid",
    researchgate: "research-gate",
    googlescholar: "Scholar",
    instagram: "Instagram",
    hal: "HAL",
  };

  const getSocialIcon = (title: string) => {
    if (!title) {
      return "linkExternal";
    }

    const normalizedTitle = normalizeString(title).replace(/\s+/g, "");
    return icons[normalizedTitle as keyof typeof icons] || "linkExternal";
  };

  return {
    memberHasSocials,
    getSocialIcon,
  };
};
