<template>
  <div v-if="testimony?.entityById" class="lg:border-b">
    <div class="container mx-auto">
      <div class="grid grid-cols-1 lg:grid-cols-12 mt-14 lg:mt-20">
        <div class="col-span-1 lg:col-span-5">
          <UiCustomLink
            v-if="backLink"
            :path="backLink"
            customClass="font-bold flex items-center lg:pt-2 font-raleway text-regular mb-[32px] lg:mb-10 hover:text-orangeCurie"
          >
            <nuxt-icon name="chevronLeft" filled class="mt-1 mr-4 text-xl" />
            {{ $t("testimony.backLink") }}
          </UiCustomLink>

          <h1 class="font-lora text-h3 leading-[45px] lg:leading-[57px] lg:text-h1 m-0 mb-4 lg:mb-0">
            {{ testimony.entityById.fieldTitle }}
          </h1>
        </div>
        <div v-if="testimony.entityById.fieldImage" class="col-span-1 lg:col-start-7 lg:col-span-8">
          <UiCustomImage
            :src="testimony.entityById.fieldImage.entity.uriRawField.list[0].url"
            :alt="testimony.entityById.fieldImage.alt || ''"
            presets="field_header"
            customClass="w-full object-cover rounded lg:rounded-none lg:rounded-tl lg:rounded-tr lg:ml-auto"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="container mx-auto">
    <div class="grid grid-cols-12 mt-14 mb-14 lg:mb-20 lg:mt-20">
      <div class="col-span-12 lg:col-start-3 lg:col-span-8 lg:pl-[110px] lg:border-l lg:border-grisClair">
        <component
          v-for="paragraph in testimony.entityById.fieldParagraphs"
          :key="paragraph.id"
          :is="getComponentByType(paragraph.__typename)"
          :paragraphData="paragraph"
          :id="paragraph.fieldAnchor || ''"
          class="my-10"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import getTestimony from "../service/graphql/client/testimony";
import { getComponentByType } from "~/utils/componentMapper";
import { useBackLink } from "~/composables/backlink";

import type { Testimony } from "~/interfaces/testimony";
import type { SeoTags } from "~/interfaces/seo";

import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
const route = useRoute();
const config = useRuntimeConfig();
const langcode = config.public.langcode;
const { getBackLink } = useBackLink();
const backLink = getBackLink();

const { data: testimony } = (await getTestimony(routeStore.route?.name?.toString()!, langcode)) as {
  data: Ref<Testimony>;
};
contextStore.setContent(testimony.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(testimony.value?.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? testimony.value?.entityById?.fieldTitle);
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? testimony.value?.entityById?.fieldParagraphs[0]?.fieldBody,
    backendMetatags?.title ?? testimony.value?.entityById?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

useCDNHeaders((helper) => {
  helper
    .private();
})
</script>
