import { NewsQuery } from "../../query/content/News";

export default async function getNews(id: string, langcode: string) {
  const TAGS : string[] = [];
  const key = `page--${id}`;
  const { data: news } = await useAsyncData(key, async () => {
    const { value, addToCache } = await useDataCache(key)
    if (value) {
      return value
    }
    const { data: response } =  await useAsyncQuery(NewsQuery, { id, langcode });
    TAGS.push(`page:${id}`);
    if(response._value.entityById?.computedNewsSameDomain){
      for (const element of response._value.entityById?.computedNewsSameDomain) {
        TAGS.push(`page:${element.id}`);
      }
    }
    await addToCache(response._value, TAGS);
    return response._value;
  })
  return {data: news._value };
}
