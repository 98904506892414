<template>
  <div class="rounded border border-grisClair mt-14 lg:mt-0">
    <div class="border-b border-grisClair font-raleway font-bold text-[20px] text-grisFont px-4 py-2">
      {{ $t("team.moreContent") }}
    </div>
    <ul>
      <li
        v-for="item in menuItems"
        :key="item.name"
        :class="item.link === activeSection ? 'font-bold' : 'font-normal'"
        class="block px-3 my-3 text-regularMobile lg:text-lg cursor-pointer font-raleway"
      >
        <div class="flex items-center justify-between">
          <a
            :href="item.link"
            @click="
              openedSection = item.link;
            "
            class="w-full"
            >{{ item.name }}
          </a>
          <NuxtIcon
            v-if="item.subMenu && item.subMenu.length > 0"
            :name="openedSection === item.link ? 'minus' : 'plus'"
            class="text-xl"
            @click="toggleSection(item.link)"
          />
        </div>
        <ul v-if="item.subMenu" v-show="openedSection === item.link" class="pl-4">
          <li
            v-for="subItem in item.subMenu"
            :key="subItem.name"
            @click="setSubMenu(subItem)"
            class="font-raleway text-md leading-[130%] text-grisFont hover:text-orangeCurie my-4"
            :class="subItem.id === activeSubMenu ? 'font-bold' : 'font-normal'"
          >
            <a :href="item.link">{{ subItem.name }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useTeamNavigation } from "@/composables/team/navigation";

import type { MenuItem } from "@/interfaces/team";

defineProps({
  menuItems: Array as PropType<MenuItem[]>,
  activeSection: String as PropType<string>,
  activeSubMenu: String as PropType<string>,
});

const emit = defineEmits([ "set-sub-menu"]);
const { setSubMenu, toggleSection, openedSection } = useTeamNavigation(emit);
</script>
