<template>
  <HubFieldHeader :headerData="HubDonationItems.entityById.fieldHeader2"/>
  <!-- Comment nous soutenir -->
  <div class="mb-14">
    <div class="container mx-auto">
      <div class="grid grid-cols-12">
        <div class="mb-14 lg:mb-10 col-span-12 lg:col-span-7">
          <h2 class="m-0 font-raleway font-normal text-[2rem] leading-[35px] lg:text-h2 pb-6 lg:pb-4">
            {{ HubDonationItems.entityById.fieldTitleHelpUs }}
          </h2>
          <p class="font-raleway text-regularMobile lg:text-regular leading-[25px] pb-6 lg:pb-4">
            {{ HubDonationItems.entityById.fieldIntroductionHelpUs }}
          </p>
          <UiCustomLink
              :path="HubDonationItems.entityById.fieldLinkHelpUs.uri.path"
              customClass="w-fit font-bold font-raleway underline flex items-center text-regular hover:text-orangeCurie arrow-link"
          >
            {{ HubDonationItems.entityById.fieldLinkHelpUs.title }}
          </UiCustomLink>
        </div>
      </div>
      <div class="grid grid-cols-1 px-4 py-6 text-white rounded lg:py-10 lg:px-12 bg-orangeCurie lg:grid-cols-12">
        <div class="col-span-1 mb-6 lg:mb-0 lg:col-span-4 lg:flex lg:items-center lg:pr-16">
          <NuxtImg
              class="block min-h-[145px] max-w-[214px] lg:max-w-[100%] lg:min-h-[auto] lg:w-[calc(100%-20px)] lg:object-cover mx-auto"
              src="/image/donation-white.svg"
              alt="Image Donation"
          />
        </div>
        <div class="col-span-1 lg:col-span-7">
          <h3 class="font-lora leading-[33px] text-medium lg:text-h2Small pb-6 lg:pb-4 m-0">
            {{ HubDonationItems.entityById.fieldTitleDonation }}
          </h3>
          <p class="font-raleway text-regularMobile lg:text-regular leading-[25px] pb-6 lg:pb-14">
            {{ HubDonationItems.entityById.fieldTextDonation }}
          </p>
          <UiCustomLink
              :path="HubDonationItems.entityById.fieldLinkDonation.uri.path"
              customClass="w-full text-center lg:w-fit text-orangeCurie bg-white  text-black  hover:bg-greyMedium cursor-pointer inline-block text-lg leading-none font-bold font-raleway rounded py-4 px-6 "
          >
            {{ HubDonationItems.entityById.fieldLinkDonation.title }}
          </UiCustomLink>
        </div>
      </div>
      <div class="grid grid-cols-1 mt-4 mb-14 lg:grid-cols-12 lg:mb-20 lg:gap-4">
        <div
            v-for="donation in HubDonationItems.entityById.fieldDonationDonation"
            class="flex flex-col col-span-1 px-4 py-6 mb-4 rounded lg:col-span-4 bg-grisBackground lg:px-6 lg:mb-0"
        >
          <h3 class="font-lora text-medium lg:text-h2Small leading-[33px] lg:leading-[40px] pb-6 lg:pb-4 m-0">
            {{ donation.fieldTitle }}
          </h3>
          <p class="font-raleway text-regularMobile lg:text-regular leading-[25px] pb-6 lg:pb-12">
            {{ donation.fieldText }}
          </p>
          <UiCustomLink
              :path="donation.fieldLink.uri.path"
              customClass="mt-auto w-full text-center lg:w-fit bg-orangeCurie hover:bg-orangeHover text-white cursor-pointer inline-block text-lg leading-none font-bold font-raleway rounded py-4 px-6"
          >
            {{ donation.fieldLink.title }}
          </UiCustomLink>
        </div>
        <!-- Comment nous soutenir end -->
      </div>
      <!-- Entreprises, collectivités, associations -->
      <div class="grid grid-cols-1 gap-6 lg:grid-cols-12">
        <div class="col-span-1 lg:col-span-7">
          <span class="font-medium font-lora text-regular text-orangeCurie">{{
              HubDonationItems.entityById.fieldSubtitleCompany
            }}</span>
          <h2
              class="font-raleway font-normal text-[2rem] leading-[35px] lg:leading-[52px] m-0 pt-3 lg:text-h2 pb-2.5 lg:pb-2.5"
          >
            {{ HubDonationItems.entityById.fieldTitleCompany }}
          </h2>
          <p class="font-raleway text-regular leading-[25px]">
            {{ HubDonationItems.entityById.fieldIntroductionCompany }}
          </p>
        </div>
        <div v-for="company in HubDonationItems.entityById.fieldDonationCompany" class="col-span-1 lg:col-span-6">
          <div class="flex flex-col p-4 h-full rounded border lg:p-10 border-grisClair">
            <img
                class="object-cover mb-4 w-full rounded-br rounded-bl lg:rounded"
                :src="company.fieldImage.entity.uriRawField.list[0].url"
                :alt="company.fieldImage.entity.altField"
            />
            <div class="font-lora text-h3Medium leading-[33px] lg:leading-[40px] lg:text-h2Small mb-2.5 lg:mb-4">
              {{ company.fieldTitle }}
            </div>
            <p class="font-raleway text-regularMobile lg:text-regular leading-[25px] pb-6 lg:pb-8">
              {{ company.fieldIntroduction }}
            </p>
            <UiCustomLink
                :path="company.fieldLink.uri.path"
                customClass="mt-auto w-full text-center lg:w-fit bg-orangeCurie hover:bg-orangeHover text-white cursor-pointer inline-block text-lg leading-none font-bold font-raleway rounded py-4 px-6 HubDonate"
            >
              {{ company.fieldLink.title }}
            </UiCustomLink>
          </div>
        </div>
      </div>
      <!-- Entreprises, collectivités, associations end -->

      <!-- Ils nous soutiennent -->
      <div class="grid grid-cols-1 mt-14 lg:grid-cols-12 lg:mt-20">
        <div class="col-span-1 lg:col-span-7">
          <h2 class="m-0 font-raleway font-normal text-[2rem] lg:text-h2 pb-6 lg:pb-4">
            {{ HubDonationItems.entityById.fieldTitleSupport }}
          </h2>
          <p class="font-raleway text-regularMobile lg:text-regular leading-[25px] pb-6">
            {{ HubDonationItems.entityById.fieldIntroductionSupport }}
          </p>
        </div>
        <div class="hidden pb-6 lg:flex lg:justify-end lg:col-start-10 lg:col-span-3">
          <UiCustomLink
              :path="HubDonationItems.entityById.fieldLinkSupport.uri.path"
              customClass="font-bold font-raleway underline flex items-center text-regular mt-auto  hover:text-orangeCurie arrow-link"
          >
            {{ HubDonationItems.entityById.fieldLinkSupport.title }}
          </UiCustomLink>
        </div>
        <div class="hidden gap-4 lg:grid lg:grid-cols-12 lg:col-span-12">
          <div
              v-for="support in HubDonationItems.entityById.fieldDonationSupport"
              class="col-span-1 lg:col-span-4 group relative"
          >
            <UiCustomLink class="underline-animation-border" :path="support.fieldLink.uri.path">
              <div class="overflow-hidden">
                <img
                    class="object-cover w-full rounded group-hover:scale-[1.1] transition ease delay-600"
                    :src="support.fieldImage.entity.uriRawField.list[0].url"
                    :alt="support.fieldImage.entity.altField"
                />
              </div>
              <div :class="support.fieldTitle ? 'mb-0' : 'mb-4'" class="mt-4 font-bold font-raleway mb-4 text-regular">
                {{ support.fieldLink.title }}
              </div>
              <p v-if="support.fieldTitle" class="font-bold font-raleway text-regular mb-4">
                {{ support.fieldTitle }}
              </p>
            </UiCustomLink>
          </div>
        </div>
        <div class="block col-span-1 lg:hidden lg:col-span-7">
          <div class="flex flex-row">
            <UiSlider>
              <swiper-slide v-for="support in HubDonationItems.entityById.fieldDonationSupport" :key="support.id">
                <UiCustomLink :path="support.fieldLink.uri.path">
                  <div class="">
                    <img
                        class="object-cover w-full rounded"
                        :src="support.fieldImage.entity.uriRawField.list[0].url"
                        :alt="support.fieldImage.entity.altField"
                    />
                    <p v-if="support.fieldLink.title" class="pb-16 mt-4 font-bold font-raleway text-regular">
                      {{ support.fieldLink.title }}
                    </p>
                  </div>
                </UiCustomLink>
              </swiper-slide>
            </UiSlider>
          </div>
        </div>
        <div class="flex col-span-1 justify-center mt-8 lg:hidden lg:col-start-10 lg:col-span-2">
          <UiCustomLink
              path="#"
              customClass="font-bold font-raleway underline flex items-center text-regular hover:text-orangeCurie arrow-link"
          >
            {{ $t("hub.ourSupport") }}
          </UiCustomLink>
        </div>
      </div>
      <!-- Ils nous soutiennent end -->
    </div>
    <section
        v-for="(section, index) in HubDonationItems.entityById.fieldSection"
        :key="index"
        :class="getSectionClass(section.fieldTypeSection)"
        class="mt-10 mb-20 lg:mt-20"
    >
      <component
          v-for="paragraph in section.fieldParagraphs"
          :key="paragraph.id"
          :is="getComponentByType(paragraph.__typename)"
          :paragraphData="paragraph"
          :id="paragraph.fieldAnchor"
          :sectionType="section.fieldTypeSection"
          class="my-10"
      />
    </section>
    <section v-if="HubDonationItems.entityById.fieldFooter" class="container mx-auto">
      <component
          v-for="paragraph in HubDonationItems.entityById.fieldFooter"
          :key="paragraph.fieldParagraph.id"
          :is="getComponentByType(paragraph.fieldParagraph.__typename)"
          :paragraphData="paragraph.fieldParagraph"
          class="my-10"
      />
    </section>
  </div>
</template>
<script setup lang="ts">
import useSeo from "~/composables/seo";
import getHubDonation from "../service/graphql/client/hub-donation";
import {getComponentByType} from "~/utils/componentMapper";
import {useContextStore} from "~/stores/context";

const contextStore = useContextStore();

import type {SeoTags} from "~/interfaces/seo";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();

const route = useRoute();
const config = useRuntimeConfig();
const langcode = config.public.langcode;
const {data: HubDonationItems} = await getHubDonation(routeStore.route?.name?.toString()!, langcode);
contextStore.setContent(HubDonationItems.value?.entityById);

const {setMetaTags, getBackendMetatags, formatSeoTag} = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(HubDonationItems.value?.entityById?.fieldMetatag);
  const title = formatSeoTag(
      "title",
      backendMetatags?.title ?? HubDonationItems.value?.entityById?.fieldHeader2?.fieldTitle
  );
  const description = formatSeoTag(
      "description",
      backendMetatags?.description ?? HubDonationItems.value?.entityById?.fieldHeader2?.fieldText1,
      backendMetatags?.title ?? HubDonationItems.value?.entityById?.fieldHeader2?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return {title, description, canonical_url: canonicalUrl, ...backendMetatags};
});

setMetaTags(formattedMetatags.value);

function getSectionClass(sectionType: string): string {
  if (sectionType === "border") {
    return "lg:max-w-[870px] mx-auto lg:border-l lg:border-grisClair xl:pl-28 px-4";
  } else if (sectionType === "normal") {
    return "mx-auto lg:max-w-[870px]";
  } else if (sectionType === "full") {
    return "container mx-auto";
  }
  return "";
}

useCDNHeaders((helper) => {
  helper
    .private();
})
</script>

<style>
.swiper-pagination {
  text-align: left;
  width: fit-content !important;
}

.swiper-pagination-bullet {
  background-color: #d7d7d7 !important;
  opacity: 1 !important;
  width: 10px !important;
  height: 10px !important;
}

.swiper-pagination-bullet-active {
  background-color: #f58220 !important;
}

.swiper-button-prev {
  left: initial !important;
  right: 32px !important;
  color: #333333 !important;
  top: initial !important;
  bottom: 0 !important;
}

.swiper-button-next {
  left: initial !important;
  right: 0 !important;
  color: #333333 !important;
  top: initial !important;
  bottom: 0 !important;
}

.swiper-button-prev:after {
  font-size: 16px !important;
  font-weight: bold;
}

.swiper-button-next:after {
  font-size: 16px !important;
  font-weight: bold;
}
</style>
