<script setup lang="ts">
import useQuery from "~/composables/query";

import type { Facet, QueryValue } from "~/interfaces/list";

const props = defineProps({
  facet: {
    type: Object as PropType<Facet>,
    required: true,
  },
  searchTerm: {
    type: String,
    required: false,
    default: "",
  },
});

const route = useRoute();
const { updateQuery } = useQuery();

const queryKey = computed(() => toCamelCase(props.facet.field) + "[]");

const getCurrentQueryValues = (fieldKey: string) => {
  const value = route.query[fieldKey];
  if (Array.isArray(value)) {
    return [...value];
  } else if (value !== undefined) {
    return [value];
  }
  return [];
};

const filters = computed(() => {
  if (!props.facet.filters) return [];

  const normalizedSearchTerm = props.searchTerm ? normalizeString(props.searchTerm) : "";
  const currentQueryValues = getCurrentQueryValues(queryKey.value);

  return props.facet.filters
    .filter((filter) => normalizeString(filter.label.value).includes(normalizedSearchTerm))
    .map((filter) => ({
      ...filter,
      checked: currentQueryValues.includes(filter.value),
    }))
    .sort((a, b) => a.label.value.localeCompare(b.label.value));
});

const handleCheckboxChange = (queryValue: string, checked: boolean) => {
  const currentValues = getCurrentQueryValues(queryKey.value);

  if (checked) {
    if (!currentValues.includes(queryValue)) {
      currentValues.push(queryValue);
    }
  } else {
    const index = currentValues.indexOf(queryValue);
    if (index > -1) {
      currentValues.splice(index, 1);
    }
  }

  updateQuery({
    [queryKey.value]: currentValues.length > 0 ? (currentValues as QueryValue) : undefined,
  });
};
</script>

<template>
  <ul v-if="filters" class="flex flex-col gap-2.5 CheckboxFilter max-h-[180px] pt-2.5 overflow-y-auto pr-4">
    <li class="flex gap-2.5" v-for="(filter, index) in filters" :key="index">
      <input
        v-model="filter.checked"
        type="checkbox"
        :value="filter.value"
        :id="`${filter.value}`"
        class="w-fit font-raleway text-regularMobile lg:text-regular shrink-0 relative top-[4px]"
        @change="handleCheckboxChange(filter.value, filter.checked)"
      />
      <label
        class="cursor-pointer font-raleway text-regularMobile lg:text-regular flex-auto flex justify-between"
        :class="{ 'font-bold text-orangeCurie': filter.checked }"
        :for="`${filter.value}`"
      >
        {{ filter.label?.value }} <span v-if="facet.hideCount !== true">({{ filter.count }})</span>
      </label>
    </li>
  </ul>
</template>

<style>
.CheckboxFilter {
  input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    color: currentColor;
    width: 18px;
    height: 18px;
    border: 1px solid #353537;
    border-radius: 0.15em;
    display: grid;
    place-content: center;
    cursor: pointer;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 10px;
    height: 10px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #f58220;
    border-radius: 2px;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
}
</style>
~/stores/list
