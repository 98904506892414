<template>
  <div
    v-if="Person.entityById.computedPersonPublication.length > 0"
    class="py-10 pt-[56px] lg:py-[60px] relative after:content-[''] after:w-[calc(100%+2rem)] after:left-[-1rem] after:absolute lg:after:w-[calc(100%+8rem)] after:h-full after:bg-grisBackground after:z-[-1] after:top-0 lg:after:left-[-5rem]"
  >
    <!-- * Composant fieldPublications -->
    <TeamPublication :title="$t('person.latestPublications')" :fieldPublications="publications" />
    <div
      v-if="Person.entityById.computedPersonPublication.length > 3"
      @click="showAllPublications = !showAllPublications"
      class="flex justify-center pt-10 lg:pt-[60px]"
    >
      <button class="pl-2 font-bold underline font-raleway text-regular hover:text-orangeCurie">
        {{ showAllPublications ? $t("person.showLessPublications") : $t("person.showAllPublications") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Person } from "@/interfaces/person";

const props = defineProps<{
  Person: Person;
}>();

const showAllPublications = ref(false);

const publications = computed(() => {
  return showAllPublications.value
    ? props.Person.entityById.computedPersonPublication
    : props.Person.entityById.computedPersonPublication.slice(0, 3);
});
</script>
