<template>
    <div class="px-4 lg:px-0">
      <div
        class="relative min-h-[550px] container flex flex-col items-center justify-center mx-auto border-b border-grisClair mb-10"
      >
        <h1
          class="lg:block absolute text-center top-0 w-full h-full font-normal text-gray-100 font-lora text-[200px] lg:text-[550px] -z-10 leading-none"
        >
          404
        </h1>
        <div class="text-center lg:w-1/2">
          <h2 class="font-raleway leading-[35px] lg:leading-[52px] font-normal text-[2rem] lg:text-h2 pb-8 m-0">
            {{ $t('404.title') }}
          </h2>
          <p class="text-regularMobile lg:text-regular  leading-[25px] font-normal font-raleway pb-8">
            {{ $t('404.text') }}
          </p>
          <UiButton
            :buttonLabel="$t('404.backLink')"
            url="/"
            size="large"
            :withIcon="false"
          />
        </div>
      </div>
    </div>

</template>

<script setup lang="ts">
const event = useRequestEvent()
 setResponseStatus(event, 404)
const config = useRuntimeConfig();
useHead({
  link: [
    { rel: "preconnect", href: "https://fonts.googleapis.com" },
    { rel: "stylesheet", href: "https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap" },
    { rel: "stylesheet", href: "https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap" }
  ],
});
</script>
