<template>
  <select
    v-model="selected"
    @change="$emit('update:selected', selected)"
    class="w-full px-3 py-[13px] border-r-4 border-r-transparent focus:outline-0 bg-transparent cursor-pointer"
  >
    <option disabled selected value="">
      {{ type === "month" ? $t("list.dateRange.month") : $t("list.dateRange.year") }}
    </option>
    <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
    </option>
  </select>
</template>

<script setup lang="ts">
const props = defineProps<{
  type: string;
  value: number | string;
}>();

defineEmits(["update:selected"]);
import { getLocale } from "~/utils/dateUtils";
const selected = ref(props.value ?? "");

const options = computed(() => {
  if (props.type === "month") {
    return Array.from({ length: 12 }, (_, i) => ({
      value: i + 1,
      label: new Date(0, i).toLocaleString(getLocale(), { month: "long" }),
    }));
  } else if (props.type === "year") {
    const currentYear = new Date().getFullYear();

    return Array.from({ length: 100 }, (_, i) => ({
      value: currentYear - i,
      label: currentYear - i,
    }));
  }

  return [];
});
</script>
