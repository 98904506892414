<template>
  <div class="relative">

    <div class="container mx-auto">
      <!-- Back Link -->
      <UiCustomLink
        v-if="backLink"
        :path="backLink"
        customClass="font-bold flex items-center font-raleway hover:text-orangeCurie text-regular mt-14 lg:mt-20 mb-[22px] lg:mb-10"
      >
        <nuxt-icon name="chevronLeft" filled class="mt-1 mr-4 text-xl" />
        {{ $t("person.backlink") }}
      </UiCustomLink>
      <!-- Back Link end -->
      <div class="grid relative grid-cols-12 mb-14 lg:mb-20">
        <PersonIdentity :Person="Person" />

        <div class="col-span-12 lg:col-start-5 lg:col-span-8 lg:pl-20 lg:border-l">
          <!-- Fonction -->
          <PersonFunction :Person="Person" />
          <!-- Fonction end -->

          <!-- Spécialité -->
          <PersonSpeciality :Person="Person" />
          <!-- Spécialité end -->

          <PersonCancerType :Person="Person" />
          <!-- Type de cancers end -->

          <!-- Présentation -->

          <!-- Présentation end -->
          <PersonIntroduction :Person="Person" />
          <!-- Publication -->

          <!-- Publication end -->
          <PersonPublication :Person="Person" />
          <!-- Actualites start -->
          <PersonNews :Person="Person" />
          <!-- Actualites end -->

          <!-- Formulaire start -->
          <PersonForm :Person="Person" />

          <!-- Formulaire end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import getPerson from "~/service/graphql/client/person/index";
import { useBackLink } from "~/composables/backlink";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
import type { SeoTags } from "~/interfaces/seo";
import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();
const route = useRoute();
const config = useRuntimeConfig();
const langcode = config.public.langcode;
const { data: Person } = await getPerson(routeStore.route?.name?.toString()!, langcode);
contextStore.setContent(Person.value?.entityById);
const name = computed(() => {
  return `${Person.value?.entityById?.fieldFirstName ?? ""} ${Person.value?.entityById?.fieldLastName ?? ""}`;
});

const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(Person.value?.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? name.value);
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? Person.value?.entityById?.fieldDescription,
    backendMetatags?.title ?? name.value
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

const { getBackLink } = useBackLink();
const backLink = getBackLink();

useCDNHeaders((helper) => {
  helper
    .private();
})
</script>
