import { gql } from "graphql-tag";

export const ListNewsQuery = gql`
  query ListNewsQuery($id: ID!, $langcode: Langcode!) {
    entityById(entityType: NODE, id: $id, langcode: $langcode) {
      ... on NodeNewsList {
        id
        langcode
        translations {
          pathRawField {
            list {
              alias
              langcode
            }
          }
        }
        fieldMetatag {
          value
        }
        fieldHeader{
            ...headerParagraph
        }
        computedNewsHighlighted {
          ... on NodeNews {
            id
            pathRawField {
              list {
                alias
              }
            }
            fieldSubtitle
            fieldTitle
            fieldDate {
              value
            }
            fieldImage {
              alt
              entity {
                uriRawField {
                  list {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment headerParagraph on ParagraphHeader {
    id
    type: entityBundle
    fieldTitle
    fieldIntroduction2
    fieldImage {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldTitlePush
    fieldTextPush: fieldBody
    fieldLinkPush: fieldLink {
      title
      uri {
        path
      }
    }
  }
`;
