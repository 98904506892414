<template>
  <div>
    <TeamTitle :title="title" />
    <div v-if="fieldPublications.length > 0" class="space-y-4">
      <div
        v-for="(publication, index) in fieldPublications"
        :key="index"
        :class="index === 0 ? 'lg:border-t lg:pt-4' : ''"
        class="flex flex-col relative gap-2.5 w-full pb-4"
      >
        <span v-if="publication.fieldDate" class="font-bold font font-raleway text-[15px] lg:text-small text-orangeCurie">
          {{ getYear(publication.fieldDate.value) }}
        </span>
        <UiCustomLink tag="h3" customClass="flex m-0 font-raleway hover:text-orangeCurie font-bold text-[19px] lg:text-medium leading-[22px] lg:leading-[26px] underline-animation-border" :path="publication.pathRawField.list[0].alias">
            {{ publication.fieldTitle }}
        </UiCustomLink>
        <p
          v-if="publication.fieldPublicationNewspaper && publication.fieldDate"
          class="italic font-raleway text-regularMobile lg:text-regular"
        >
          <span>{{ publication.fieldPublicationNewspaper?.name }}</span> -
          {{ formatDate(publication.fieldDate?.value) }}
        </p>
        <p
          v-if="publication.fieldAuthor"
          class="flex font-raleway items-center text-grisFont text-regularMobile lg:text-regular font-normal leading-[26px] cursor-pointer italic"
          @click="isAuthorActive = isAuthorActive === index ? null : index"
        >
          {{ $t("seeAuthors") }}
          <NuxtIcon :name="isAuthorActive === index ? 'chevronUp' : 'chevronDown'" filled class="mt-1 ml-2 text-xl" />
        </p>
        <div v-if="isAuthorActive === index">
          <ul class="flex flex-wrap gap-1">
            <p class="font-raleway italic text-regularMobile lg:text-regular" v-html="publication.fieldAuthor">
            </p>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="allPublicationsLink" class="my-14 lg:mt-20 lg:mb-16">
      <UiCustomLink
        :path="allPublicationsLink"
        customClass="lg:inline lg:w-auto lg:text-left block w-full text-center font-raleway text-lg font-bold px-6 py-4 border border-grisClair rounded hover:text-orangeCurie"
      >
        {{ $t('publication.seeAll') }}
      </UiCustomLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBackLink } from "~/composables/backlink";
import { formatDate } from "~/utils/dateUtils";

import type { FieldPublication } from "~/interfaces/publication";

const props = defineProps<{
  fieldPublications: FieldPublication[];
  title: string;
  teamId?: string;
  unitId?: string;
}>();

const { getBackLink } = useBackLink();

const getYear = (date: string) => {
  if (!date) return "";

  return new Date(date).getFullYear().toString();
};

const isAuthorActive = ref<number | null>(null);

const allPublicationsLink = computed(() => {
  const backLink = getBackLink("publication_list");
  if (!backLink) return null;

  if (props.teamId) {
    return `${backLink}?computedPublicationTeam[]=${props.teamId}`;
  }

  if (props.unitId) {
    return `${backLink}?computedPublicationUnit[]=${props.unitId}`;
  }

  return null;
});
</script>
