<template>
  <HubFieldHeader :headerData="HubTreatmentItems.entityById.fieldHeader2" />
  <!-- Header hub soin selection -->
  <div class="mb-14 lg:mb-20">
    <div class="container mx-auto">
      <div class="grid-flow-col lg:grid lg:grid-cols-12">
        <div
          class="col-span-1 px-4 py-6 lg:col-span-7 lg:px-12 lg:py-10 lg:pb-11 border border-grisClair rounded-tr rounded-tl lg:flex lg:flex-col lg:justify-center"
        >
          <h2 class="m-0 font-lora font-normal leading-[33px] text-h3Medium lg:text-h2Small pb-6">
            {{ $t("hub.listTitle") }}
          </h2>
          <div class="flex flex-col gap-4 lg:gap-2 lg:flex-row lg:items-center">
            <UiDropdownCancer class="" :listItems="HubTreatmentItems.entityById.fieldCancerLink" />
          </div>
          <UiCustomLink
            :path="HubTreatmentItems.entityById.fieldAllCancerLink.uri.path"
            customClass="w-fit font-raleway pt-4 lg:pt-6 underline flex items-center text-regular hover:text-orangeCurie cursor-pointer"
          >
            {{ HubTreatmentItems.entityById.fieldAllCancerLink.title }}
          </UiCustomLink>
        </div>
        <div class="col-span-1 border border-t-0 border-grisClair lg:col-span-7 flex items-center">
          <div class="px-4 py-6 lg:px-12 lg:py-6">
            <div class="flex flex-col gap-2 lg:flex-row lg:gap-10">
              <div class="font-raleway text-regular">{{ $t("hub.hospitals") }} :</div>
              <ul class="flex gap-8">
                <li
                  v-for="hospital in HubTreatmentItems.entityById.fieldHospitalLink"
                  :key="hospital.title"
                  class="font-bold underline font-raleway text-regular"
                >
                  <UiCustomLink
                    :path="hospital.uri.path"
                    customClass="font-bold font-raleway underline flex items-center text-regular hover:text-orangeCurie"
                  >
                    {{ hospital.title }}
                  </UiCustomLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-span-1 row-span-2 lg:col-span-5 bg-grisBackground lg:ml-4 font-raleway text-regularMobile lg:text-regular rounded border border-grisBackground mt-2.5 lg:mt-0"
        >
          <div class="px-4 py-6 lg:px-10 lg:py-10">
            <h2 class="pb-6 m-0 font-normal font-lora text-h3Medium lg:text-h2Small">
              {{ HubTreatmentItems.entityById.fieldTitleTreatment }}
            </h2>
            <ul class="flex flex-col gap-4">
              <li v-for="item in HubTreatmentItems.entityById.fieldTreatmentLink" :key="item.title">
                <UiCustomLink
                  :path="item.uri.path"
                  customClass="font-bold font-raleway w-fit underline flex items-center text-regular hover:text-orangeCurie"
                >
                  {{ item.title }}
                </UiCustomLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <section
      v-for="(section, index) in HubTreatmentItems.entityById.fieldSection"
      :key="index"
      :class="getSectionClass(section.fieldTypeSection)"
      class="mt-10 mb-20 lg:mt-20"
    >
      <component
        v-for="paragraph in section.fieldParagraphs"
        :key="paragraph.id"
        :is="getComponentByType(paragraph.__typename)"
        :paragraphData="paragraph"
        :id="paragraph.fieldAnchor"
        :sectionType="section.fieldTypeSection"
        class="my-10"
      />
    </section>
    <section class="container mx-auto" v-if="HubTreatmentItems.entityById.fieldFooter">
      <component
        v-for="paragraph in HubTreatmentItems.entityById.fieldFooter"
        :key="paragraph.fieldParagraph.id"
        :is="getComponentByType(paragraph.fieldParagraph.__typename)"
        :paragraphData="paragraph.fieldParagraph"
        class="my-10"
      />
    </section>
  </div>
  <!-- Header hub soin selection end -->
</template>
<script setup lang="ts">
import useSeo from "~/composables/seo";
import getHubTreatment from "../service/graphql/client/hub-treatment";
import { getComponentByType } from "~/utils/componentMapper";
import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();

import type { SeoTags } from "~/interfaces/seo";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();

const route = useRoute();
const config = useRuntimeConfig();
const langcode = config.public.langcode;
const { data: HubTreatmentItems } = await getHubTreatment(routeStore.route?.name?.toString()!, langcode);
contextStore.setContent(HubTreatmentItems.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(HubTreatmentItems.value?.entityById?.fieldMetatag);
  const title = formatSeoTag(
    "title",
    backendMetatags?.title ?? HubTreatmentItems.value?.entityById?.fieldHeader2?.fieldTitle
  );
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? HubTreatmentItems.value?.entityById?.fieldHeader2?.fieldText1,
    backendMetatags?.title ?? HubTreatmentItems.value?.entityById?.fieldHeader2?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

function getSectionClass(sectionType: string): string {
  if (sectionType === "border") {
    return "lg:max-w-[870px] mx-auto lg:border-l lg:border-grisClair xl:pl-28 px-4";
  } else if (sectionType === "normal") {
    return "lg:max-w-[870px] mx-auto";
  } else if (sectionType === "full") {
    return "container mx-auto";
  }
  return "";
}

useCDNHeaders((helper) => {
  helper
    .private();
})
</script>
