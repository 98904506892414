import { gql } from "graphql-tag";

export const PersonQuery = gql`
  query PersonQuery($id: ID!, $langcode: Langcode!) {
    entityById(entityType: NODE, id: $id, langcode: $langcode) {
      id
      ... on NodePerson {
        id
        langcode
        translations {
          pathRawField {
            list {
              alias
              langcode
            }
          }
        }
        fieldMetatag {
          value
        }
        fieldRegistrationNumber
        fieldFirstName
        fieldLastName
        fieldPersonTitle {
          name
        }
        fieldPersonSite {
          name
        }
        fieldPersonLocation {
          name
        }
        fieldLink {
          title
          uri {
            path
          }
        }
        fieldImage {
          alt
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
        computedPersonFunction
        fieldPersonSpecializedMedicin {
          name
        }
        fieldPersonSpeciality {
          name
        }
        fieldPersonCancerType {
          name
        }
        fieldDescription
        computedPersonNews {
          ... on NodeNews {
            id
            pathRawField {
              list {
                alias
              }
            }
            fieldSubtitle
            fieldTitle
            fieldDate {
              value
            }
            fieldImage {
              alt
              entity {
                uriRawField {
                  list {
                    url
                  }
                }
              }
            }
          }
        }
        computedPersonPublication {
          ... on NodePublication {
            id
            pathRawField {
              list {
                alias
              }
            }
            fieldTitle
            fieldDate {
              value
            }
            fieldPublicationNewspaper {
              name
            }
            fieldAuthorCurie {
              fieldFirstName
              fieldLastName
              pathRawField {
                list {
                  alias
                }
              }
            }
          }
        }
        fieldMessage
        fieldPersonPhone {
          fieldTitle
          fieldPhone
        }
        fieldPersonEmail {
          fieldEmail
          fieldTitle
        }
      }
    }
  }
`;
