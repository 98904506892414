import type { MenuItem, SubItem } from "@/interfaces/team";

export const useTeamNavigation = (emit: (event: "set-sub-menu", ...args: any[]) => void) => {


  const setSubMenu = (subItem: SubItem) => {
    emit("set-sub-menu", subItem.id);
  };

  const openedSection = ref<string | null>(null);

  const toggleSection = (link: string) => {
    if (openedSection.value === link) {
      openedSection.value = null;
    } else {
      openedSection.value = link;
    }
  };

  return {setSubMenu, toggleSection, openedSection};
};
