<template>
  <form class="flex flex-row items-center mb-4 lg:mb-10" @submit.prevent="searchWithQuery">
    <div class="relative w-full">
      <input
        v-model="query"
        type="text"
        class="text-regularMobile lg:text-regular w-full px-4 pr-10 self-stretch py-[12px] lg:py-[20.5px] rounded-r-none font-raleway text-grisFont placeholder:text-grisFont placeholder:lg:text-regular placeholder:text-regularMobile rounded-l border border-grisClair focus:outline-0 border-r-0"
        :placeholder="$t(`list.searchForm.${list}`)"
      />
      <button
        v-if="query"
        class="absolute right-0 top-0 h-full px-4 text-grisFont"
        type="button"
        @click="clearInput"
      >
        ×
      </button>
    </div>
    <div
      @click="searchWithQuery"
      class="p-[15px] lg:p-[25px] max-h-[51.5px] lg:max-h-[70px] bg-orangeCurie flex justify-center items-center self-stretch rounded-tr-[3px] rounded-br-[3px]"
    >
      <input value=" " class="w-0" type="submit" />
      <NuxtIcon
        name="search"
        filled
        class="text-white cursor-pointer text-[18px] lg:text-[20px] mb-0"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import useQuery from "@/composables/query";

const { query } = storeToRefs(useList());
const { searchWithQuery } = useQuery();

defineProps<{
  list: string;
}>();

const clearInput = () => {
  query.value = "";
  searchWithQuery();
};
</script>
